.body, body { 
	font-family: $font-body1;
	font-weight: 400;
	font-style: normal;
	line-height: 1;
	color: $gray-dark1;
}

.body {
	p {
		font-size: 1.0em;
		line-height: 1.5;
		a {
			color: $brandcolor1;
			text-decoration: none;
			&:hover {
				color: $brandcolor1-light;
				text-decoration: underline;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-dark;
				text-decoration: underline;
			}
		}
	}

	ul {
		list-style: none; /* Remove default bullets */
		margin: 1.0em 0;
	}

	ul li::marker {
		content: "\2580";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $brandcolor1; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		// display: inline-block; /* Needed to add space between the bullet and the text */
		// width: 2.5em;  /* Also needed for space (tweak if needed) */
		// margin-left: -2.5em; /* Also needed for space (tweak if needed) */
		// transform: translateY(0.25em);
		font-size: 0.75em;
	}

	ul li {
		line-height: 1;
		margin: 0.66em -0.5em;
		padding-left: 0.5em;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	ul li a {
		// font-weight: 400;
		// text-decoration: underline;
		// color: $black !important;
	}

	ul li a:hover {
		// color: $brandcolor1 !important;
	}

	ol li {
		line-height: 1.25;
		margin: 0.66em 0;
	}

	ul.unstyled {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			border-bottom: 1px solid $gray-light1;
			&:first-child { 
				// padding-top: 0; 
			}
			&:last-child { 
				border-bottom: none; 
			}
			&::marker {
				content: "";
				display: none;
			}
			b {
				color: $black;
				font-weight: 700;
			}
		}
	}

	ul.list-2column {
		// margin-top: 0;
		// padding-top: 0.9375em;
		// border-top: 1px solid $brandcolor2;
		column-count: 2;
		column-gap: 2.5em;
		column-rule: 1px dotted $gray-white2;
		// @include media-breakpoint-only(lg) {
		// 	column-count: 1;
		// }
		@include media-breakpoint-only(xs) {
			column-count: 1;
		}
	}
}

p {
	font-size: 1.0em;
	line-height: 1.5;
}

p.lead, .lead p {
	font-family: $font-body1;
	color: $black;
	font-weight: 100;
	font-size: 1.25em;
	line-height: 1.5;
}

p.text-small, .text-small p, .text-small {
	font-size: 0.875em;
	line-height: 1.25;
}

b {
	font-weight: 700;
}

a {
	color: $brandcolor1;
	text-decoration: none;
	&:hover {
		color: $brandcolor1-light;
		text-decoration: underline;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: underline;
	}
}

h1.display-1 {
	font-family: $font-heading1;
	font-weight: 700;
	font-size: 5em;
	font-style: italic;
	text-transform: uppercase;
	line-height: 1.0625;
	letter-spacing: -0.025em;
	color: $gray-dark1;
}

h1.display-2 {
	font-family: $font-body1;
	font-weight: 700;
	font-size: 4.5em;
	font-style: italic;
	line-height: 1.0625;
	letter-spacing: -0.025em;
	color: $brandcolor2;
}

h1.display-3 {
	font-family: $font-body1;
	font-weight: 300;
	font-size: 5em;
	text-transform: uppercase;
	line-height: 1.0625;
	letter-spacing: -0.025em;
	color: $gray-dark1;
}

h1.display-4 {
	font-family: $font-body1;
	font-weight: 300;
	font-size: 4.5em;
	line-height: 1.0625;
	letter-spacing: -0.025em;
	color: $brandcolor2;
}

h1 {
	font-family: $font-body1;
	font-weight: 700;
	font-size: clamp(3.0em, calc(3.0rem + 1.0 * ((100vw - 36em) / 39)), 4.0em);
	line-height: 0.875;
	// font-size: 4em;
	letter-spacing: -0.0125em;
	color: $gray-dark1;
}

h2 {
	font-family: $font-heading1;
	font-weight: 700;
	font-size: 3.0em;
	font-style: italic;
	text-transform: uppercase;
	line-height: 1.0625;
	letter-spacing: -0.025em;
	color: $brandcolor2;
}

h3 {
	font-family: $font-heading1;
	font-weight: 700;
	font-size: 2em;
	line-height: 1.0625;
	letter-spacing: -0.025em;
	color: $gray-dark1;
}

h4 {
	font-family: $font-heading1;
	font-weight: 700;
	font-size: 1.75em;
	font-style: italic;
	text-transform: uppercase;
	line-height: 1.0625;
	letter-spacing: -0.025em;
	color: $brandcolor2;
}

h5 {
	font-family: $font-body1;
	font-weight: 700;
	font-size: 1.5em;
	line-height: 1.0625;
	color: $gray-dark1;
}

h6 {
	font-family: $font-heading1;
	font-weight: 700;
	font-size: 1.25em;
	// font-style: italic;
	// text-transform: uppercase;
	line-height: 1.0625;
	letter-spacing: 0;
	color: $brandcolor2;
}

.text-light, .text-light p, .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light ul li::before, .text-light ul li::before {
	color: $white !important;
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5em;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5em;
	line-height: 1;
}

h2.page-headline-text {
	margin-bottom: 0.625em;
}

h4.page-subheadline-text {
	margin-top: 0.625em;
}

h5.header-sm {
	font-family: $font-heading1;
	font-size: 1.125em;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

h6.header-xs {
	font-size: 0.875em;
	font-style: normal;
	letter-spacing: 0.025em;
}

.h1-italic-bold {
	h1 {
		font-style: italic;
		text-transform: uppercase;
		line-height: 0.8;
		strong {
			opacity: 0.75;
		}
	}
}

