.entry-pages-red-zone-technologies {
	h2, h4 {
		color: $brandcolor3;
	}
	.red-zone-products-container {
		padding: 0 2.5rem 7.5rem 2.5rem;
		.red-zone-products-category-container {
			.red-zone-products-category-title-row {
				padding: 2.5rem 0 0 0;
				.red-zone-products-category-title-container {
					@media (max-width: 767.9px) {
						margin: 0;
						padding: 0;
					}
				}
			}
			.red-zone-products-row {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 1.25rem 0;
				border-bottom: 1px solid $gray-light2;
				&:last-child {
					border-bottom: none;
				}
				.red-zone-products-desc-container {
					@media (max-width: 767.9px) {
						padding: 0.3125rem 0;
					}
					.red-zone-product-title {
						margin-bottom: 0;
						a {
							color: $gray-dark1;
							text-decoration: none;
							&:hover {
								color: $brandcolor3;
								text-decoration: none;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								color: $brandcolor3-dark;
								text-decoration: none;
							}
						}
					}
					.red-zone-product-desc {
						display: block;
						margin-top: 0 0 0.3125rem 0;
					}
				}
				.red-zone-products-buttons-container {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					gap: 0.3125rem;
					@media (max-width: 767.9px) {
						padding: 0.3125rem 0 0 0;
						justify-content: flex-start;
						align-items: center;
					}
				}
			}
		}
	}
}