.hosted-video-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 0%;
}

.video-thumb-container {
	position: relative;
	overflow: hidden;
	&:hover {
		img {
			opacity: 0.5;
			filter: blur(4px);
			-webkit-filter: blur(4px);
			-moz-filter: blur(4px);
			-ms-filter: blur(4px);
			-o-filter: blur(4px);
		}
		.video-thumb-overlay {
			background-color: rgba($gray-dark3, 0.0);
			// backdrop-filter: blur(4px);
			// -webkit-backdrop-filter: blur(4px);
			// -moz-backdrop-filter: blur(4px);
			// -ms-backdrop-filter: blur(4px);
			// -o-backdrop-filter: blur(4px);
			.video-thumb-button-container {
				background-color: rgba($brandcolor1, 0.75);
				.video-thumb-button {
					color: $white;
					.link-text-nav {
						color: $white;
					}
					.fac, .fa {
						color: $white;
					}
				}
			}
		}
	}
	img {
		opacity: 1;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
	}
	.video-thumb-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($gray-dark3, 0.33);
		display: flex;
		justify-content: center;
		align-items: center;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
		.video-thumb-button-container {
			padding: 1.25em;
			border-radius: 1.25em;
			background-color: rgba($brandcolor1-dark, 0.75);
			backdrop-filter: blur(4px);
			-webkit-backdrop-filter: blur(4px);
			-moz-backdrop-filter: blur(4px);
			-ms-backdrop-filter: blur(4px);
			-o-backdrop-filter: blur(4px);
			.video-thumb-button {
				text-align: center;
				font-family: $font-heading1;
				color: $gray-light1;
				font-size: 0.5em;
				text-transform: uppercase;
				letter-spacing: 0.25em;
				transition: all 1.0s ease-out;
				-webkit-transition: all 1.0s ease-out;
				-moz-transition: all 1.0s ease-out;
				-ms-transition: all 1.0s ease-out;
				-o-transition: all 1.0s ease-out;
				transition: all 1.0s ease-out;
				.link-text-nav {
					color: $gray-light1;
				}
				.fac, .fa {
					font-size: 6.0em;
					padding-bottom: 0.125em;
					z-index: 6;
				}
			}
		}
	}
}
