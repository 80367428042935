// Blocks General
.block-headline-text {
	margin: 0;
	line-height: 1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 0.3125em 0 0 0;
	text-transform: none;
	line-height: 1;
}

.background-image-overlay {
	position: absolute;
	z-index: -10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	// -webkit-background-size: cover;
	// -moz-background-size: cover;
	// -o-background-size: cover;
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: center;
	@media (max-width: 767.9px) {
		background-attachment: scroll !important;
	}
}

.block-container-full, .block-container-content {
	position: relative;
}

.block-overlay-full, .block-overlay-content {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.divider-block-intro {
	visibility: hidden;
	margin: 0.3125rem 0;
}



// Images
.img-regular-padding {
	padding: 0 1.25em;
	@media (max-width: 991.9px) {
		padding: 2.5em 1.25em;
	}
}

.img-no-padding {
	padding: 0;
	@media (max-width: 991.9px) {
		padding: 2.5em 0;
	}
}

.img-square-crop, .img-circle-crop {
	position:relative;
}
.img-square-crop::after, .img-circle-crop::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.img-square-crop img, .img-circle-crop img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-repeat: no-repeat;
	@media (max-width: 991.9px) {
		padding: 2.5em;
	}
}

.img-circle-crop img {
	border-radius: 50%;
}

.img-overlay-sections {
	position: absolute;
	right: 2.5em;
	top: 0;
	@media (max-width: 991.9px) {
		position: static;
		padding: 2.5em 1.25em;
	}
}




// Large Text Callout
.large-text-callout-container {
	h2.large-text-callout-headline-text {
		font-weight: 400;
		font-size: 2em;
		color: $gray-dark2;
	}
	h3.large-text-callout-headline-text {
		font-size: 1.75em;
	}
	h4.large-text-callout-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}

	h4.large-text-callout-subheadline-text {
		font-weight: 700;
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.large-text-callout-subheadline-text {
		font-weight: 700;
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.large-text-callout-subheadline-text {
		font-weight: 400;
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.large-text-callout-body-copy {
		p {
			font-size: 2.5em;
			line-height: 1.2em;
		}
	}
}




//Pull Quote
.pull-quote-container {
	h2.pull-quote-headline-text, h2.image-callout-headline {
		font-weight: 400;
		font-size: 2em;
		color: $gray-dark2;
	}
	h3.pull-quote-headline-text, h3.image-callout-headline {
		font-size: 1.75em;
	}
	h4.pull-quote-headline-text, h4.image-callout-headline {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-headline-text, h5.image-callout-headline {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h4.pull-quote-subheadline-text, h4.image-callout-subheadline {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-subheadline-text, h5.image-callout-subheadline {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.pull-quote-subheadline-text, h6.image-callout-subheadline {
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.pull-quote-body-copy, .image-callout-body-copy {
		p {
			font-size: 1.625rem !important;
			line-height: 1.25 !important;
		}
	}
	cite.pull-quote-citation, cite.image-callout-citation {
		display: block;
		color: $gray-dark3;
		font-size: 1.5em;
		font-style: normal;
		font-weight: 700;
		line-height: 1em;
		margin: 0.625em 0 1.25em 0;
		padding: 0;
	}
}




// Text-With-Image Block
.block-container-text-with-image {
	.block-text-image-body-column {
		padding: 0 0.9375em !important;
		.block-text-image-branding-image-container {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1.875em 0;
			.block-text-image-branding-image {
				width: 40%;
			}
		}
		.text-one-column-headline-text {
			margin-bottom: 0.9375em;
		}
	}
	.img-background-fill {
		width: 100%;
		height: 100%;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: left center;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		img, .image-icon-image {
			visibility: hidden;
		}
	}
}




//Stat Facts Row
.stat-fact-number {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 5em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-prefix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-suffix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-desc {
	margin: 0;
	font-size: 1.2em;
	line-height: 1;
}
.stat-fact-citation {
	margin: 0;
	padding-top: 0.625em;
	font-size: 0.9em;
	font-weight: 300;
	line-height: 1;
	color: $gray-mid3;
}




//Image Callout Cards
.image-callout-card {
	border: none;
	border-radius: 0;
}
.image-callout-headline {
}
.image-callout-body p {
	font-size: 1.0em;
	line-height: 1.2em;
}



//Image Icon Row
.image-icon-container {
	padding: 1.25em;
}
.image-icon-headline {
	margin: 0.3125em 0 0 0;
	color: $gray-mid3;
}
.image-icon-link {
	&:hover {
		text-decoration: none;
	}
}
.image-icon-text p {
	color: $gray-dark2;
	line-height: 1.2em;
	margin-top: 1.25em;
}
.image-icon-card {
	height: 100%;
	margin: 0;
	padding: 0 0 2.5em 0;
	display: flex;
	justify-content: center;
	align-items: flex-start; 
	.image-icon-card-img-top {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		.image-icon-image {
			margin: 0;
			padding: 0;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.image-icon-card-body {
		width: 100%;
		margin: 0;
		padding: 0;
		align-self: flex-end;
		flex-grow: 0;
	}
	.image-icon-card-footer {
		margin: 0;
		padding: 0;
		align-self: flex-end;
	}
}


//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; overflow:
	hidden;
	max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



//Image Gallery Block
.block-container-image-gallery {
	.block-image-gallery-thumb-container {
		padding: 0.3125em;
		.block-image-gallery-thumb {

		}
	}
	.block-image-gallery-masonry-grid-sizer,
	.block-image-gallery-masonry-grid-item {
		padding: 0.3125em;
	}
}




//Background Image Callout Cards
.background-callout-card {
	width: 100%;
	border: none;
	border-radius: 0;
	.card-img {
		border-radius: 0;
	}
	.card-img-overlay {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding-bottom: 5vw;
		.background-callout-headline {
			color: $white;
			font-size: 2.25em;
			line-height: 0.875;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}
		.background-callout-body p {
			font-size: 1.0em;
			line-height: 1.2em;
		}
	}
}





//Product List Block
.block-container-product-list {
	.headline-row {
		border-bottom: 1px solid $gray-light1;
		// box-shadow: 0 1px 0 0 $white;
	}
	.cards-row {
		padding-top: 1.25em;
		@media (max-width: 991.9px) {
			// .product-list-block-slider {
			// 	width: calc(100% - 1.25em);
			// }
		}
		// display: flex;
		// justify-content: space-between;
		// align-items: flex-start;
		// flex-wrap: wrap;
		// gap: 1.25em;
		.product-list-block-slider {
			z-index: 0;
			.product-list-block-slider-wrapper {
				z-index: 0;
				.product-list-block-slide {
					z-index: 0;
				}
			}
		}
		.swiper-button-next, .swiper-button-prev {
			&::after {
				font-size: 1.75em;
				color: $gray-mid2;
			}
			&:hover {
				&::after {
					color: $brandcolor1-light;
				}
			}
			&:focus-visible {
				outline: none;
			}
		}
		.swiper-button-prev {
			left: -50px;
			@media (max-width: 991.9px) {
				left: -30px;
			}
		}
		.swiper-button-next {
			right: -50px;
			@media (max-width: 991.9px) {
				right: -30px;
			}
		}
		.subheadline-container {
		}
		.card {
			width: 100%;
			// width: calc(33.33% - 0.9375em);
			// @media (max-width: 991.9px) {
			// 	width: calc(50% - 1.25em);
			// }
			// @media (max-width: 575.9px) {
			// 	width: 100%;
			// }
		}
	}
}



//Product Category Block
.block-container-product-categories {
	.headline-row {
		border-bottom: 1px solid $gray-light1;
		@media (max-width: 575.9px) {
			padding: 0 0 1.25rem 0;
		}
	}
	.cards-row {
		.block-product-category-teaser-container {
			padding-top: 1.25em;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			gap: 1.25em;
			.subheadline-container {
			}
			.card {
				padding: 1.25em 0;
				width: calc(33.33% - 0.9375em);
				@media (max-width: 991.9px) {
					width: calc(50% - 1.25em);
				}
				@media (max-width: 575.9px) {
					width: 100%;
				}
			}
		}
	}
}



//Sales Rep Slider
.block-container-sales-rep-slider {
	position: relative;
	z-index: 1;
	.sales-rep-slider-body-column {
		@media (max-width: 575.9px) {
			padding: 2.5rem 1.875rem;
		}
		.block-headline-text {
			margin-bottom: 0.625em;
		}
		.btn {
			padding-left: 3.75em;
			padding-right: 3.75em;
		}
	}
	.sales-rep-slider-image-column {
		margin: 0;
		padding: 0;
		@media (max-width: 575.9px) {
			border-bottom: 1px solid $gray-light2;
		}
		.people-slider {
			margin: 0;
			padding: 0;
			width: 100%;
			z-index: 0;
			overflow: visible;
		}
		.people-slider-wrapper {
			overflow: visible;
			// -webkit-transition-timing-function: linear !important;
			// -moz-transition-timing-function: linear !important;
			// -ms-transition-timing-function: linear !important;
			// -o-transition-timing-function: linear !important;
			// transition-timing-function: linear !important;
			// -webkit-transition-duration: 1s !important;
			// -moz-transition-duration: 1s !important;
			// -ms-transition-duration: 1s !important;
			// -o-transition-duration: 1s !important;
			// transition-duration: 1s !important;
		}
		.people-slide {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: visible;
			.swiper-slider-animate-opacity {
				opacity: 0.175;
				-webkit-filter: blur(24px);
				-moz-filter: blur(24px);
				-ms-filter: blur(24px);
				-o-filter: blur(24px);
				filter: blur(24px);
				transform: scale(0.9);
				transform-origin: bottom center;
				-webkit-transition: transform 1.0s ease-in-out, opacity 0.25s ease-in, filter 1.0s ease-in-out;
				-moz-transition: transform 1.0s ease-in-out, opacity 0.25s ease-in, filter 1.0s ease-in-out;
				-ms-transition: transform 1.0s ease-in-out, opacity 0.25s ease-in, filter 1.0s ease-in-out;
				-o-transition: transform 1.0s ease-in-out, opacity 0.25s ease-in, filter 1.0s ease-in-out;
				transition: transform 1.0s ease-in-out, opacity 0.25s ease-in, filter 1.0s ease-in-out;
				.img {
					max-width: initial !important;
					display: block;
					// width: auto;
					// height: 100%;
					object-fit: cover;
					overflow: visible;
				}
			}
		}
		.swiper-slide-active {
			.swiper-slider-animate-opacity {
				opacity: 1;
				transform: scale(1.125);
				-webkit-filter: blur(0px);
				-moz-filter: blur(0px);
				-ms-filter: blur(0px);
				-o-filter: blur(0px);
				filter: blur(0px);
				z-index: 2;
			}
		}
		.swiper-slide-prev, .swiper-slide-next {
			.swiper-slider-animate-opacity {
				opacity: 0.25;
				transform: scale(0.9);
				-webkit-filter: blur(4px);
				-moz-filter: blur(4px);
				-ms-filter: blur(4px);
				-o-filter: blur(4px);
				filter: blur(4px);
			}
		}
	}
	.block-overlay {
		pointer-events: none;
		position: absolute;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba($gray-dark3, 0);
		background: -moz-linear-gradient(180deg, rgba($gray-dark3,0) 70%, rgba($gray-dark3,0.25) 85%, rgba($gray-dark3,0.5) 100%);
		background: -webkit-linear-gradient(180deg, rgba($gray-dark3,0) 70%, rgba($gray-dark3,0.25) 85%, rgba($gray-dark3,0.5) 100%);
		background: linear-gradient(180deg, rgba($gray-dark3,0) 70%, rgba($gray-dark3,0.25) 85%, rgba($gray-dark3,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
		@media (max-width: 575.9px) {
			display: none;
		}
	}
}



//Client Logo Block
.block-client-logo-slider {
	width: 100%;
	// height: calc(45px + 2.5em);
	height: 120px;
	margin: 0;
	padding: 1.25em 0 0 0;
	border-left: 1px solid $white;
	border-right: 1px solid $white;
	.block-client-logo-slider-wrapper {
		// -webkit-transition-timing-function: linear !important;
		// -moz-transition-timing-function: linear !important;
		// -ms-transition-timing-function: linear !important;
		// -o-transition-timing-function: linear !important;
		// transition-timing-function: linear !important;
		.block-client-logo-slide {
			display: flex;
			justify-content: center;
			align-items: center;
			width: auto;
			max-width: 240px;
			max-height: 120px;
			.block-client-logo-image {
				width: auto;
				max-width: 240px;
				max-height: 120px;
				opacity: 0.5;
				-webkit-filter: blur(0px) grayscale(100%);
				-moz-filter: blur(0px) grayscale(100%);
				-ms-filter: blur(0px) grayscale(100%);
				-o-bfilter: blur(0px) grayscale(100%);
				filter: blur(0px) grayscale(100%);
				-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-o-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				transition: filter 1.0s ease-out, opacity 1.0s ease-out;
			}
		}
		.swiper-slide-active {
			.block-client-logo-image {
				opacity: 1.0;
				border: none;
				-webkit-filter: blur(0) grayscale(0);
				-moz-filter: blur(0) grayscale(0);
				-ms-filter: blur(0) grayscale(0);
				-o-bfilter: blur(0) grayscale(0);
				filter: blur(0) grayscale(0);
			}
		}
	}
	.block-client-logo-slider-overlay {
		position: absolute;
		pointer-events: none;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background: -moz-linear-gradient(90deg, rgba($white,1) 0%, rgba($white,0) 25%, rgba($white,0) 75%, rgba($white,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba($white,1) 0%, rgba($white,0) 25%, rgba($white,0) 75%, rgba($white,1) 100%);
		background: linear-gradient(90deg, rgba($white,1) 0%, rgba($white,0) 25%, rgba($white,0) 75%, rgba($white,1) 100%);
		border-left: 1px solid $white;
		border-right: 1px solid $white;
	}
}



//Callout Card Slider Block
.block-container-callout-cards-slider {
	.headline-row {
		// border-bottom: 1px solid $gray-light1;
		// box-shadow: 0 1px 0 0 $white;
	}
	.cards-row {
		padding-top: 1.25em;
		@media (max-width: 991.9px) {
			// .product-list-block-slider {
			// 	width: calc(100% - 1.25em);
			// }
		}
		.product-list-block-slider {
			z-index: 0;
			.product-list-block-slider-wrapper {
				z-index: 0;
				.product-list-block-slide {
					z-index: 0;
				}
			}
		}
		.swiper-button-next, .swiper-button-prev {
			&::after {
				font-size: 1.75em;
				color: $gray-mid2;
			}
			&:hover {
				&::after {
					color: $brandcolor1-light;
				}
			}
			&:focus-visible {
				outline: none;
			}
		}
		.swiper-button-prev {
			left: -50px;
			@media (max-width: 991.9px) {
				left: -30px;
			}
		}
		.swiper-button-next {
			right: -50px;
			@media (max-width: 991.9px) {
				right: -30px;
			}
		}
		.subheadline-container {
		}
		.card {
			width: 100%;
			.image-callout-headline {
				color: $brandcolor1;
				margin-bottom: 0.25rem;
			}
			.image-callout-subheadline {
				color: $gray-mid2;
				text-transform: none;
			}
			.image-callout-body-copy {
				display: block;
				margin-top: 0.3125rem;
				p {
					color: $gray-mid2;
					font-size: 1.0rem;
					line-height: 1.25;
					// overflow: hidden;
					// display: -webkit-box;
					// -webkit-line-clamp: 6;
					// -webkit-box-orient: vertical;
					// text-overflow: ellipsis;
				}
			}
			.card-footer {
				padding: 0;
			}
		}
	}
}

