// Basic Colors
$white: #FFFFFF;
$black: #1a1a1a;

// Main Brand Colors
$brandcolor1-light: #0068a8;
$brandcolor1: #004876;
$brandcolor1-dark: #002942;

$brandcolor1-white1: #f2faff;
$brandcolor1-white2: #e5f5ff;
$brandcolor1-white3: #d9f0ff;
$brandcolor1-white4: #bfe5ff;

$brandcolor1-light1: #99d6ff;
$brandcolor1-light2: #66c2ff;
$brandcolor1-light3: #33adff;

$brandcolor2-light: #00b877;
$brandcolor2: #008556;
$brandcolor2-dark: #005235;

$brandcolor2-white1: #e5f3ee;
$brandcolor2-white2: #cce7dd;
$brandcolor2-white3: #b2dacc;
$brandcolor2-white4: #9cd9c4;

// Red Zone Colors
$brandcolor3-light: #ff4249;
$brandcolor3: #cd2127;
$brandcolor3-dark: #99090e;

// Grays
$gray-white0: #fffbf5;
$gray-white1: #f6f2ec;
$gray-white2: #ede9e4;
$gray-white3: #e3e0da;

$gray-light1: #d6d3ce;
$gray-light2: #bfbab4;
$gray-light3: #ada5a0;

$gray-mid1: #968e87;
$gray-mid2: #857b75;
$gray-mid3: #706760;

$gray-dark1: #574e49;
$gray-dark2: #3d3632;
$gray-dark3: #241f1d;

//Alert Colors
$alertcolor-light: #ff4249;
$alertcolor: #cd2127;
$alertcolor-dark: #99090e;

//Quote Status Colors
$quote-status-draft-white: #bfbab4;
$quote-status-draft-dark: #857b75;

$quote-status-new-white: #ffc766;
$quote-status-new-dark: #e69100;

$quote-status-open-white: #d9f0ff;
$quote-status-open-dark: #0068a8;

$quote-status-quoting-white: #d1fffd;
$quote-status-quoting-dark: #21ccc6;

$quote-status-accepted-white: #b2dacc;
$quote-status-accepted-dark: #00b877;

$quote-status-declined-white: #ffd1d3;
$quote-status-declined-dark: #cd2127;

$quote-status-expired-white: #ff824d;
$quote-status-expired-dark: #ff4d00;

$quote-status-unknown-white: #ff8fe9;
$quote-status-unknown-dark: #ff29d4;

$quote-status-closed-white: #e3d1ff;
$quote-status-closed-dark: #4100a8;




//Main Brand Fonts
$font-body1: 'Roboto', Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-body1-mobile: 'Roboto Condensed', 'Century Gothic', Impact, Helvetica, Calibri, 'Segoe UI', sans-serif;

$font-heading1: 'Roboto Condensed', 'Century Gothic', Impact, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-heading1-mobile: 'Roboto Condensed', 'Century Gothic', Impact, Helvetica, Calibri, 'Segoe UI', sans-serif;

$font-nav1: 'Roboto Condensed', 'Century Gothic', Impact, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-nav1-mobile: 'Roboto Condensed', 'Century Gothic', Impact, Helvetica, Calibri, 'Segoe UI', sans-serif;

$font-button1: 'Roboto Condensed', 'Century Gothic', Impact, Helvetica, Calibri, 'Segoe UI', sans-serif;
$font-button1-mobile: 'Roboto Condensed', 'Century Gothic', Impact, Helvetica, Calibri, 'Segoe UI', sans-serif;

//Icon Fonts
$font-icons1: "Font Awesome 5 Free";

//Tag collections
$svg-tags: 'path, circle, rect, polygon, font';



/******************************************************************************************
PIXEL TO REM CONVERSION TABLE - Root Size: 16px
-------------------------------------------------------------------------------------------
PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM
-------------------------------------------------------------------------------------------
10 = 0.625   |  20 = 1.25    |  30 = 1.875   |  40 = 2.5     |  50 = 3.125   |  60 = 3.75
11 = 0.6875  |  21 = 1.3125  |  31 = 1.9375  |  41 = 2.5625  |  51 = 3.1875  |  61 = 3.8125
12 = 0.75    |  22 = 1.375   |  32 = 2       |  42 = 2.625   |  52 = 3.25    |  62 = 3.875
13 = 0.8125  |  23 = 1.4375  |  33 = 2.0625  |  43 = 2.6875  |  53 = 3.3125  |  63 = 3.9375
14 = 0.875   |  24 = 1.5     |  34 = 2.125   |  44 = 2.75    |  54 = 3.375   |  64 = 4
15 = 0.9375  |  25 = 1.5625  |  35 = 2.1875  |  45 = 2.8125  |  55 = 3.4375
16 = 1       |  26 = 1.625   |  36 = 2.25    |  46 = 2.875   |  56 = 3.5
17 = 1.0625  |  27 = 1.6875  |  37 = 2.3125  |  47 = 2.9375  |  57 = 3.5625
18 = 1.125   |  28 = 1.75    |  38 = 2.375   |  48 = 3       |  58 = 3.625
19 = 1.1875  |  29 = 1.8125  |  39 = 2.4375  |  49 = 3.0625  |  59 = 3.6875
-------------------------------------------------------------------------------------------
******************************************************************************************/
