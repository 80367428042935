.embed-responsive {
	overflow:hidden;
	//padding-bottom:56.25%;
	position:relative;
	height:0;
}
.embed-responsive iframe {
	left:0;
	top:0;
	height:100%;
	width:100%;
	position:absolute;
}
.embed-responsive-16by9 {
	padding-bottom: 54.25%;
}
.embed-responsive-3by4 {
	&::before {
		padding-top: percentage(4 / 3);
	}
}

.adminbar {
  z-index: 1000 !important;
}
.adminbar--sticky {
  position: relative !important;
}


.badge {
	padding: 0.25rem 0.3175rem 0.1875rem 0.25rem;
	transform: translateY(-0.25rem);
}
.badge-danger {
	background-color: $alertcolor;
	color: $white;
	line-height: 0.8175;
	letter-spacing: -.05rem;
}
