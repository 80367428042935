/* Customer Quote Navigation */
.customer-quote-navigation-container {
	padding: 1.25rem 1.875rem;
	@media (max-width: 767.9px) {
		padding: 1.875rem 1.875rem 0 1.875rem;
	}
	.customer-quote-navigation-tabs {
		border-bottom: 1px solid $gray-mid2;
		.nav-link {
			padding: 0.9375rem 2.1875rem;
			border-radius: 0.375em 0.375em 0 0;
			background-color: $gray-white0;
			border: 1px solid $gray-mid2;
			border-bottom: 1px solid $gray-mid2;
			color: $brandcolor1;
			font-size: 1.0rem;
			&:hover {
				background-color: $white;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				background-color: $gray-white0;
			}
			&.active {
				color: $brandcolor2;
				background-color: $gray-white0;
				border: 1px solid $gray-mid2;
				border-bottom: 1px solid $gray-white0;
			}
			@media (max-width: 767.9px) {
				padding: 0.625rem 1.25rem;
			}
		}
	}
}


/* Customer Quote List */
.customer-quote-products-layout-container {
	margin: 0;
	padding: 0;
}
.customer-quote-list-container {
	padding: 1.25em 1.875rem 6.25rem 1.875rem;
	font-size: 0.9375em;
	@media (max-width: 767.9px) {
		padding: 1.25em 1.875rem 0rem 1.875rem;
	}
	.customer-quote-list-table {
		display: grid;
		grid-template-columns: minmax(min-content, 0.25fr) minmax(min-content, max-content) repeat(2, minmax(min-content, 0.25fr)) 1fr  minmax(min-content, max-content);
		grid-gap: 0.5em 0.5em;
		// justify-items: start;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			a {
				font-weight: 400;
				color: $black;
				text-decoration: none;
				&:hover {
					color: $brandcolor1-light;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
		.table-header {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
		}
		.table-header-divider {
			grid-column: span 6;
			border-bottom: 1px solid $gray-mid2;
			margin: 0.125em 0 0.3125em 0;
			height: 1px;
		}
		.table-row-divider {
			grid-column: span 6;
			padding: 0;
			border-bottom: 1px solid $gray-light2;
			margin: 0.125em 0;
			height: 1px;
			&:first-child {
				// border-bottom: 1px solid $gray-mid2;
				// margin: 0;
			}
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
		.table-col-messages {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			white-space: nowrap;
		}
		.table-col-products {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		.table-col-link {
			height: 100%;
			a, button {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				position: relative;
				color: $brandcolor1;
				height: 100%;
			}
		}
		@media (max-width: 575.9px) {
			grid-template-columns: repeat(5, minmax(min-content, 1fr)) minmax(min-content, max-content);
			.table-col-header {
				display: none;
			}
			.table-col-product-title {
				grid-column: 1 / span 3;
			}
			.table-col-messages {
				grid-column: 4 / span 3;
				justify-self: flex-end;
				justify-content: flex-end;
			}
			.table-col-date-modified:not(.table-col-header) {
				grid-column: 1 / span 3;
				display: block;
			}
			.table-col-date-created:not(.table-col-header) {
				grid-column: 4 / span 3;
				justify-self: flex-end;
				justify-content: flex-end;
				display: block;
			}
			.table-col-products {
				grid-column: 1 / span 5;
				-webkit-line-clamp: 2;
			}
		}
	}
}

/* Quote Info List */
.customer-quote-info-list-container {
	background-color: $gray-light2;
	padding: 1.875rem 1.25rem;
	width: 100%;
	.customer-quote-info-list-table {
		display: grid;
		grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr);
		grid-gap: 0.3125em 0.0625em;
		font-size: 0.875em;
		padding: 1.25em 0;
		.table-col, .table-col-label, .table-col-long {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col-label {
			justify-self: start;
			text-align: left;
			font-weight: 700;
		}
		.table-col {
			justify-self: end;
			text-align: right;
		}
		.table-col-long {
			@media (min-width: 767.9px) {
				grid-column: span 2;
				justify-self: start;
				text-align: left;
			}
		}
		.table-row-divider {
			grid-column: span 2;
			padding: 0;
			border-bottom: 1px solid $white;
			margin: 0.3125em 0;
			height: 1px;
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
	}
	.customer-quote-info-list-buttons-container {
		.btn-duplicate-quote {
			color: $brandcolor1;
			&:hover {
				color: $white;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $white;
			}
		}
	}
}

/* Quote Products Edit List */
.customer-quote-products-overview-container {
	padding: 2.5rem 1.875rem;
	font-size: 1.125em;
	@media (max-width: 767.9px) {
		padding: 0.625rem 1.875rem;
	}
	.customer-quote-products-overview-table {
		display: grid;
		grid-template-columns: minmax(min-content, max-content) minmax(min-content, 1fr);
		grid-gap: 0.3125em 1.25em;
		justify-content: flex-start;
		align-items: center;
		@media (max-width: 767.9px) {
			grid-gap: 0.3125em 0.625em;
		}
		.table-col, .table-col-label {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col-label {
			margin-bottom: 0;
			color: $brandcolor2;
			font-weight: 700;
		}
		.table-col {
		}
		.table-row-divider {
			grid-column: span 2;
			padding: 0;
			border-bottom: 1px solid $gray-light2;
			margin: 0.3125em 0;
			height: 1px;
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
		.table-col-label-freight-progress {
			align-self: start;
		}
		.table-col-freight-progress {
			display: grid;
			grid-template-columns: minmax(min-content, max-content) 1fr;
			grid-gap: 0.3125em;
			align-self: start;
			.progress-bar-label {
				grid-column: span 2;
				color: $gray-mid2;
				font-size: 0.875em;
			}
		}
	}
}
.customer-quote-products-list-edit-title-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.625em 1.875em;
	.customer-quote-products-list-edit-title-container {
		color: $brandcolor2;
		font-size: 1.125em;
		font-weight: 700;
	}
	.customer-quote-products-list-edit-discount-form {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		column-gap: 0.3125em;
		.input-quote-product-list-discount-percent {
			border-radius: 0.375em 0 0 0.375em !important;
		}
		.btn-apply-discount {
			padding: 0.5em 2.5em;
		}
	}
}
.customer-quote-products-list-edit-table-container {
	padding: 1.875rem 1.875em 0 1.875em;
	.customer-quote-products-list-edit-table {
		margin-bottom: 0;
		display: grid;
		grid-template-columns: minmax(min-content, 1.5fr) repeat(2, minmax(min-content, max-content)) minmax(min-content, 1fr) repeat(2, minmax(min-content, max-content)) minmax(min-content,0.5fr) minmax(min-content, max-content);
		// grid-gap: 0.75em 0.5em;
		justify-items: start;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col {
			padding: 0.75em;
		}
		.table-col-header {
			padding: 0.75em 0.75em 0.375em 0.75em;
			padding-bottom: 0.375em;
		}
		.table-row-divider {
			grid-column: 1 / span 8;
			margin: 0;
			padding: 0;
			border-bottom: 1px solid $gray-light2;
			&:first-child {
				border-top: 1px solid $gray-mid2;
			}
			// &:last-child {
			// 	border-bottom: 1px solid $gray-mid2;
			// }
		}
		.table-row-divider-header {
			border-bottom: 1px solid $black;
		}
		.table-row-divider-addons {
			border-bottom: 1px solid $brandcolor2-white3;
		}
		.table-row-divider-addons-footer {
			border-bottom: 1px solid $brandcolor2;
		}
		.table-row-divider-footer {
			border-bottom: 1px solid $gray-mid2;
		}
		.table-col-product-title {
			justify-self: stretch;
			padding-right: 0.75rem;
		}
		.table-col-qty-needed {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			white-space: nowrap;
		}
		.table-col-pack-size {
			justify-self: stretch;
		}
		.table-col-quote-price {
			text-align: right;
			justify-self: stretch;
			input {
				font-weight: bold;
			}
		}
		.table-col-delete-button, .table-col-add-button {
			a, button {
				color: $gray-mid2;
				&:hover {
					color: $brandcolor1-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
				}
			}
		}
		.table-col-product-title-removed {
			text-decoration: line-through;
			a {
				color: $gray-mid2;
				font-weight: 400;
				&:hover {
					color: $brandcolor1-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
				}
			}
		}
		.table-col-removed {
			color: $gray-mid2;
		}
		.table-col-product-notes {
			grid-column: 1 / span 8;
			justify-self: stretch;
			background-color: $gray-white1;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 1.25em;
			padding: 0.375em 0.75em;
			font-size: 0.875em;
			.product-notes-chip {
				b {
					color: $brandcolor2;
				}
			}
		}
		.table-col-add-product {
			grid-column: 1 / span 8;
			margin: 0;
			justify-self: stretch;
			padding: 0.625em;
			background-color: $gray-white2;
			// border-bottom: 1px solid $gray-mid2;
			.quote-detail-add-product-button {
				//background-color: $white;
				padding: 0.625em 2em 1em 2em;
				.fa, .fac {
					font-size: 1.625em;
					transform: translateY(0.125em);
				}
				@media (max-width: 575.9px) {
					width: 100%;
				}
			}
		}
		.table-col-header-addons {
			grid-column: 1 / span 8;
			justify-self: stretch;
			position: relative;
			padding: 0.3125em;
			color: $brandcolor2;
			background-color: $brandcolor2-white2;
			font-size: 0.875em;
			font-weight: 700;
			&:before {
				display: inline-block;
				position: absolute;
				top: -0.625em;
				left: 0.3125em;
				content: '\f032';
				font-family: 'ReichmanSales';
				font-size: 2.0em;
				font-weight: normal;
				font-style: normal;
				line-height: 1;
				text-rendering: auto;
				color: $brandcolor2-white2;
			}
		}
		.table-col-addons {
			padding: 0.5em 0;
			justify-self: stretch;
			align-self: stretch;
			background-color: $brandcolor2-white1;
			font-size: 0.875em;
			a {
				font-weight: 400;
			}
			.fa, .fac {

			}
			&.table-col-product-title {
				padding-left: 0.5em;
			}
		}
		.table-total-col {
			padding: 1.25em 0;
			justify-self: stretch;
			align-self: stretch;
			background-color: $brandcolor1-white2;
			border-top: 2px solid $black;
			border-bottom: 1px solid $black;
		}
		.table-col-total-label {
			grid-column: 1 / span 6;
			padding: 1.25em 0.625em;
			color: $brandcolor2;
			font-size: 1.125em;
			font-weight: 700;
		}
		.table-col-total-quote-price {
			text-align: right;
			justify-self: stretch;
		}
		input {
			padding: 0.125em 0.25em !important;
			height: auto;
		}
		select {
			padding: 0.125em 1.0em 0.125em 0.25em !important;
			height: auto;
		}
		select:not(.input-style-default) {
			font: inherit;
			display: inline-block;
			background: transparent;
			outline: none !important;
			border: none;
			border-radius: 0;
			border-spacing: 0;
			padding: 1px 2px;
			line-height: 1.4em;
			padding: 0;
			margin: 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-appearance: none;
			-moz-appearance: none;
			box-shadow: none;
			-webkit-box-shadow: none;
			cursor: pointer;
		}
		input[type="text"]:not(.input-style-default),
		input[type="number"]:not(.input-style-default) {
			font: inherit;
			display: inline-block;
			background: transparent;
			outline: none !important;
			border: transparent;
			border-radius: 0;
			border-spacing: 0;
			padding: 1px 2px;
			box-shadow: none;
			-webkit-box-shadow: none;
			transition: all 100ms linear;
			overflow: visible;
			line-height: 1.4em;
			cursor: pointer;
			&.width-auto {
				text-align: right;
				width: calc(3ch + 4px);
			}
			&.empty,
			&:focus {
				cursor: auto;
				background: $white;
				border: 1px solid $gray-light2;
				border-radius: 0.375em !important;
				-webkit-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
				-moz-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
				box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
			}
			&[data-type="currency"].width-auto {
				width: calc(6ch + 6px);
			}

		}
		@media (max-width: 575.9px) {
			grid-template-columns: repeat(7, minmax(min-content, 1fr)) minmax(min-content, max-content);
			.table-col {
				padding: 0.3125em 0.25em;
			}
			.table-col-header {
				display: none;
			}
			.table-col-product-title {
				grid-column: 1 / span 4;
				// padding-bottom: 0;
			}
			.table-col-manufacturer {
				grid-column: 5 / span 4;
				justify-self: stretch;
				// padding-top: 0;
				text-align: right;
			}
			.table-col-msds-link, .table-col-label-link {
				display: none;
			}
			.table-col-label-link {
				display: none;
			}
			.table-col-qty-needed {
				grid-column: 1 / span 3;
				justify-self: stretch;
			}
			.table-col-pack-size {
				grid-column: 4 / span 5;
				justify-self: stretch;
				select {
					width: 100% !important;
				}
			}
			.table-col-quote-price {
				grid-column: 1 / span 7;
				// justify-self: stretch;
			}
		}
	}
}

/* Quoted Messages Layout */
.customer-quote-messages-layout-container {
	display: grid;
	grid-template-columns: 1fr minmax(17.5em, .20fr);
	margin: 0;
	padding: 0;
	@media (max-width: 767.9px) {
		grid-template-columns: 100%;
	}
	.main-content-column {
	}
	.customer-quote-info-column {
		background-color: $gray-white2;
		@media (max-width: 767.9px) {
			order: 2;
		}
	}
}

/* Quote Message History */
.customer-quote-message-history-title-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.875em;
	.customer-quote-message-history-title-container {
		padding: 0.625em 0;
		color: $brandcolor2;
		font-size: 1.125em;
		font-weight: 700;
		border-bottom: 1px solid $gray-light2;
	}
}
.customer-quote-message-history-container {
	padding: 0.625em 1.875em 7.5rem 1.875rem;
	.customer-quote-message-container {
		padding: 0.625em 0;
		.customer-quote-message-title-container {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-bottom: 0.625em;
			//gap: 0.625em;
			.customer-quote-message-title-name {
				color: $black;
			}
			.customer-quote-message-title-date {
				color: $gray-mid2;
				margin-left: 0.625em;
				padding: 0.3125em 0.625em;
				border-left: 2px solid $brandcolor2;
			}
		}
		.customer-quote-message-body-row {
			margin: 0;
			.customer-quote-message-body-container {
				position: relative;
				border-radius: 0.375em;
				padding: 1.25em;
				box-shadow:
					0px 0.5px 1.1px rgba(0, 0, 0, 0.035),
					0px 1.5px 3px rgba(0, 0, 0, 0.05),
					0px 3.6px 7.2px rgba(0, 0, 0, 0.065),
					0px 12px 24px rgba(0, 0, 0, 0.1);
				p {
					font-size: 1.125em;
					line-height: 1.125;
					margin-bottom: 0;
				}
				&:before {
					display: inline;
					position: absolute;
					top: -0.59375em;
					z-index: 2;
					content: '\f032';
					font-family: 'ReichmanSales';
					font-size: 2.0em;
					font-weight: normal;
					font-style: normal;
					line-height: 1;
					text-rendering: auto;
				}
			}
		}
	}
	.customer-quote-message-customer-container {
		margin-right: 3.75em;
		.customer-quote-message-title-container {
			justify-content: flex-start;
		}
		.customer-quote-message-body-container {
			background-color: $white;
			border: 1px solid $gray-light2;
			border-radius: 0 0.375em 0.375em 0.375em;
			p {
				color: $gray-dark2;
			}
			&:before {
				left: 0.625em;
				color: $white;
				text-shadow: 0 -1px 1px $gray-light2;
			}
		}
	}
	.customer-quote-message-agent-container {
		margin-left: 3.75em;
		.customer-quote-message-title-container {
			justify-content: flex-end;
		}
		.customer-quote-message-body-container {
			background-color: $brandcolor1-white1;
			border: 1px solid $brandcolor1-light2;
			border-radius: 0.375em 0.375em 0 0.375em;
			p {
				color: $gray-mid2;
			}
			&:before {
				right: 0.625em;
				color: $brandcolor1-white1;
				text-shadow: 0 -1px 1px $brandcolor1-light2;
			}
		}
	}
}
.customer-quote-message-send-container-outer {
	margin: 3.75rem 0 0 0;
	padding: 0;
	background-color: $brandcolor1-white2;
	.customer-quote-message-send-editor-container {
		margin: 0;
		padding: 1.875em 1.25em 0 1.25em;
		textarea, .redactor-box {
			margin: 0 !important;
		}
	}
	.quote-message-send-button-container {
		padding: 0.625em 1.25em 7.5em 1.25em;
		@media (max-width: 575.9px) {
			.btn {
				width: 100%;
			}
		}
	}
	.quote-message-send-agent-row {
		// padding-left: 3.75em;
	}
	.quote-message-send-customer-row {
		// padding-right: 3.75em;
	}
}

/* Quote Notes History */
.customer-quote-note-history-container {
	padding: 0.625em 1.875em;
	.customer-quote-note-container {
		padding: 0.625em 0;
		.customer-quote-note-title-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 0.625em;
			.customer-quote-note-title {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 0.625em;
				.customer-quote-note-title-name {
					color: $black;
				}
				.customer-quote-note-title-date {
					color: $gray-mid2;
					// margin-left: 0.625em;
					padding: 0.3125em 0.625em;
					border-left: 2px solid $brandcolor2;
				}
			}
			.customer-quote-note-buttons {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 0.625em;
			}
		}
		.customer-quote-note-body-row {
			margin: 0;
			.customer-quote-note-body-container {
				position: relative;
				border-radius: 0.375em;
				padding: 1.25em;
				background-color: $white;
				border: 1px solid $gray-light2;
				border-radius: 0 0.375em 0.375em 0.375em;
				box-shadow:
					0px 0.5px 1.1px rgba(0, 0, 0, 0.035),
					0px 1.5px 3px rgba(0, 0, 0, 0.05),
					0px 3.6px 7.2px rgba(0, 0, 0, 0.065),
					0px 12px 24px rgba(0, 0, 0, 0.1);
				p {
					color: $gray-dark2;
					font-size: 1.125em;
					line-height: 1.125;
					margin-bottom: 0;
				}
				&:before {
					display: inline;
					position: absolute;
					top: -0.59375em;
					z-index: 2;
					left: 0.625em;
					color: $white;
					text-shadow: 0 -1px 1px $gray-light2;
					content: '\f032';
					font-family: 'ReichmanSales';
					font-size: 2.0em;
					font-weight: normal;
					font-style: normal;
					line-height: 1;
					text-rendering: auto;
				}
			}
		}
	}
	.customer-quote-message-customer-container {
		.customer-quote-message-body-container {
			background-color: $white;
			border: 1px solid $gray-light2;
			border-radius: 0 0.375em 0.375em 0.375em;
			p {
				color: $gray-dark2;
			}
			&:before {
				left: 0.625em;
				color: $white;
				text-shadow: 0 -1px 1px $gray-light2;
			}
		}
	}
}
