.sign-up-form-container, .profile-form-container {
	margin: 0;
	padding: 0 1.25em 0 0;
	@media (max-width: 767.9px) {
		padding: 0;
	}
	.row {
		margin: 0;
		padding: 0.125em 0;
		.col-12, .col-6, .col-4, .col-3 {
			margin: 0;
			padding: 0;
		}
		input {
			margin: 0 0 0.625em 0;
		}
		select {
			margin: 0 0 0.625em 0;
		}
		label {
			color: $gray-mid3;
			font-weight: 700;
		}
	}
	.sign-up-state-container, .profile-state-container {
		padding: 0 0.625em !important;
		select {
			padding: 0.5em;
		}
	}
	.form-group-well {
		margin: 0.3125em 0 !important;
		padding: 0.625em !important;
		border-radius: 0.375em;
		background-color: $gray-white1;
		border: 1px solid $gray-light2;
		@media (max-width: 575.9px) {
			margin: 0.3125em -0.9375rem !important;
			padding: 0.9375em !important;
			min-width: calc(100vw - 1.875rem) !important;
		}
	}
	.sign-up-dealer-well, .profile-dealer-well {
		background-color: $brandcolor2-white1;
		border: 1px solid $brandcolor2-white4;
	}
	.sign-up-address-well, .profile-address-well {
		background-color: $gray-white1;
		border: 1px solid $gray-light2;
		.sign-up-shipping-same-container {
			padding: 0.3125rem 0 0 0;
		}
	}
	.sign-up-password-well, .profile-password-well {
		background-color: $brandcolor1-white1;
		border: 1px solid $brandcolor1-white4;
	}
	.sign-up-required-fields-text-container {
		padding: 0.3125rem 0 1.25rem 0 !important;
		color: $brandcolor2;
		font-weight: 700;
	}
}
.sign-up-intro, .profile-intro {
	margin: 0;
	padding: 0 0 0 1.25em;
	@media (max-width: 767.9px) {
		padding: 0;
	}
}

#accountLogin {
	backdrop-filter: blur(6px);
		.modal-dialog {
		.modal-content {
			background-color: $white;
			border-radius: 0;
			border: none;
			box-shadow:
				2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
				6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
				12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
				22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
				41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
				100px 100px 80px rgba(0, 0, 0, 0.07);
			.modal-body {
				padding: 1.25em;
				.row {
					margin: 0.625em 0;
					label {
						margin: 0.625rem 0 0.3125rem 0;
						color: $gray-mid3;
						font-weight: 700;
					}
					.btn {
						margin-top: 1.25rem;
					}
				}
			}
			.modal-footer {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}
		}
	}
}

.modal-backdrop.show{
	background-color: rgba($gray-mid2, 0.5);
}

/* Login Form */
.login-form-card {
	.card-body {
		label {
			margin: 0.625rem 0 0 0;
			color: $gray-mid3;
			font-weight: 700;
		}
		.btn {
			margin-top: 1.25rem;
		}
	}
	.card-footer {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
}