/* General Form Inputs */
.custom-checkbox, .custom-switch {
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-color: $brandcolor1;
		}
	}
	.custom-control-label {
		padding: 0.625rem 0 0 0;
	}
}

.form-control:focus {
	border-color: $brandcolor1;
	-webkit-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
	-moz-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
	box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

button.active.focus, button.active:focus, button.focus, button.focus:active, button:active:focus, button:focus {
	outline: 0 !important;
	outline-offset: 0  !important;
	background-image: none  !important;
	box-shadow: none;
	// -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25) !important;
	// box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25)  !important;
}

input[type="text"],
input[type="button"],
input[type="submit"],
input[type="email"],
input[type="tel"],
input[type="number"] {
	padding: 0.1875em;
	border: 1px solid $gray-light2;
	border-radius: 0.375em !important;
	box-shadow:inset 0 0.125em 0 $gray-white2;
	-moz-box-shadow:inset 0 0.125em 0 $gray-white2;
	-webkit-box-shadow:inset 0 0.125em 0 $gray-white2;
}

select {
	padding: 0.125em;
	border: 1px solid $gray-light2;
	border-radius: 0.375em !important;
	box-shadow:inset 0 -0.0625em 0 $gray-white2;
	-moz-box-shadow:inset 0 -0.0625em 0 $gray-white2;
	-webkit-box-shadow:inset 0 -0.0625em 0 $gray-white2;
	outline: none;
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 98% 50%;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

textarea {
	padding: 0.3125em;
	border: 1px solid $gray-light2;
	border-radius: 0 !important;
	box-shadow:inset 0 0.125em 0 $gray-white2;
	-moz-box-shadow:inset 0 0.125em 0 $gray-white2;
	-webkit-box-shadow:inset 0 0.125em 0 $gray-white2;
}

select.form-control {
	padding: 0.25em 0.625em;
	height: auto !important;
}

select.custom-select {
	font-size: 0.875em;
	// padding: 0 0 0 0.625em;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: $gray-light3;
	opacity: 1; /* Firefox */
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: $gray-light3;
}
.form-control::-ms-input-placeholder { /* Microsoft Edge */
	color: $gray-light3;
 }

.form-label-required {
	color: $brandcolor2;
}

.form-check-input:not(:checked), .form-check-input:checked {
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

[type="button"] {
	outline: none;
	box-shadow: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.btn-group-toggle {
	margin: 0 1.25em;
}

.btn-outline-group {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 400 !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
	background-color: none !important;
	color: $gray-dark2 !important;
	border: 1px solid $gray-light2 !important;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1 !important;
		border: 1px solid $gray-light2 !important;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light !important;
		border: 1px solid $gray-light2 !important;
		box-shadow: 0 !important;
		outline: none !important;
	}
}

.btn-outline-group:not(:disabled):not(.disabled):active,
.btn-outline-group:not(:disabled):not(.disabled).active,
.show>.btn-outline-group.dropdown-toggle {
	color: $white !important;
	background-color: $gray-dark1 !important;
}

.quote-add-button,
.quote-delete-button {
	background: none;
	border: none;
}

label {
	color: $gray-mid2;
	font-size: 0.875em;
}

label.required:after  {
 color: $brandcolor2;
}

a.chip {
	padding: 0.625em 1.25em;
	margin: 0.3125em;
	text-indent: 0.3125em;
	background-color: $white;
	color: $gray-mid2;
	font-family: $font-heading1;
	font-size: 0.75em;
	font-weight: 700;
	border-radius: 500px;
	border: 1px solid $gray-light2;
	text-decoration: none;
	&:hover {
		color: $brandcolor1-light;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
	}
	.fa, .fac {
		color: $brandcolor1;
		&:hover {
			color: $brandcolor1-light;
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1-dark;
			text-decoration: none;
		}
	}
}


/* Freeform Forms */
.freeform-form-container {
	.form-group {
		margin-top: 0.9375rem;
	}
	label {
		color: $gray-mid3;
		font-weight: 700;
		// margin-top: 0.3125rem;
	}
}

form[data-order-status="draft"] button[name="quote-action"]:not([value="send"]):not([value="draft"]):not([value="save"]):not([value="decline"]),
form[data-order-status="new"] button[name="quote-action"]:not([value="draft"]):not([value="save"]):not([value="quote"]):not([value="decline"]),
form[data-order-status="quoting"] button[name="quote-action"]:not([value="save"]):not([value="quote"]):not([value="accept"]):not([value="decline"]),
form[data-order-status="open"] button[name="quote-action"]:not([value="accept"]):not([value="agree"]):not([value="draft"]):not([value="decline"]):not([value="save"]),
form[data-order-status="agreed"] button[name="quote-action"]:not([value="accept"]):not([value="decline"]),
form[data-order-status="accepted"] button[name="quote-action"],
form[data-order-status="declined"] button[name="quote-action"],
form[data-order-status="expired"] button[name="quote-action"] {
	display: none;
	pointer-events: none;
}

.quote-message-send-row button[name="quote-action"]:disabled {
	pointer-events: none;
	opacity: 0;
}
