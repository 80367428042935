.table {
	line-height: 1.05;
	a {
		font-weight: 700;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			text-decoration: underline;
		}
	}
	.table-col-header {
		padding-bottom: 0.75em;
		font-family: $font-heading1;
		color: $gray-mid2;
		font-weight: 700;
		align-self: flex-end;
	}
	.table-row-divider {
		border-bottom: 1px solid $gray-light2;
		&:last-child {
			border-bottom: 1px solid $gray-mid2;
		}
	}
	.table-col-link {
		height: 100%;
		a {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			position: relative;
			color: $brandcolor1;
			height: 100%;
		}
	}
}

.grid-table {
	display: grid;
	// .table-row, .table-header-row {
	// 	display: grid;
	// }
}