/* Products Main Page */
.product-category-teaser-container {
	margin: 0;
	padding: 0 0 0 1.875em;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
	gap: 1.25em;
	@media (max-width: 991.9px) {
		padding: 0;
	}
	@media (max-width: 575.9px) {
		justify-content: center;
	}
	.card {
		padding: 1.25em 0;
		width: calc(50% - 1.25em);
		@media (max-width: 991.9px) {
			width: calc(50% - 1.25em);
		}
		@media (max-width: 575.9px) {
			width: 100%;
		}
	}
}

/* Product Category Teaser Cards */
.product-category-teaser-card {
	padding: 1.25em 0;
	width: calc(33.33% - 0.9375em);
	@media (max-width: 991.9px) {
		width: calc(50% - 1.25em);
	}
	@media (max-width: 575.9px) {
		width: 100%;
	}
	&:hover {
		.card-title {
			color: $brandcolor1-light;
		}
		svg.card-img {
			#{$svg-tags} {
				fill: $brandcolor1-light !important;
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-title {
			color: $brandcolor1-dark;
		}
		svg.card-img {
			#{$svg-tags} {
				fill: $brandcolor1-dark !important;
			}
		}
	}
	.card-header {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		color: $brandcolor1;
		@media (max-width: 575.9px) {
			justify-content: center;
		}
	}
	.card-title {
		margin-bottom: 0;
		color: $brandcolor1;
		.fa, .fac {
			font-size: 0.5em;
			transform: translateY(-0.1875em);
		}
	}
	.card-img {
		width: 20%;
		padding-right: 0.625em;
	}
	svg.card-img {
		#{$svg-tags} {
			fill: $brandcolor1 !important;
		}
	}
	.card-body {
	}
}

