html {
	box-sizing: border-box;
	background-color: $brandcolor2;
	// overflow-x: hidden;
	// font-size: clamp(0.875em, calc(0.875em + 0.125 * ((100vw - 36em) / 39)), 1.0em);
	// scroll-behavior: smooth;
	// font-size: calc(0.875em + 0.125 * ((100vw - 36em) / 39));
	// font-size: -webkit-calc(0.875em + 0.125 * ((100vw - 36em) / 39));
	// font-size: -moz-calc(0.875em + 0.125 * ((100vw - 36em) / 39));
	// @media (max-width: 575.9px) {
	// 	font-size: 0.875em;
	// }
	// @media (min-width: 1200px) {
	// 	font-size: 1.0em;
	// }
}

// @media screen and (prefers-reduced-motion: reduce) {
// 	html {
// 		scroll-behavior: auto;
// 	}
// }

*,
*::before,
*::after { box-sizing: inherit; }

body {
	position: relative;
	background-color: $gray-white0;
	margin: 0 !important;
	overflow-x: hidden;
}

main, .body {
	background-color: $gray-white0;
	// overflow-x: hidden;
	// overflow-y: initial;
}

.hide-vis {
	display: none;
}

.require-ajax {
	input, select, label, textarea, button {
		opacity: 0;
		pointer-events: none;
		transition: opacity 250ms linear;
	}
}


.ajax-enabled {
	.require-ajax {
		input, select, label, textarea, button {
			pointer-events: initial;
			opacity: 1;
		}
	}
}

.jumbotron-fluid {
	position: relative;
	z-index: 1;
}

.container-fluid {
	width: 100%;
	max-width: 100%;
}

.container {
	//max-width: 100% !important;
}

.container-max {
	width: 80%;
	max-width: 1800px;
	-webkit-transition: width 0.1s linear;
	-moz-transition: width 0.1s linear;
	-ms-transition: width 0.1s linear;
	-o-transition: width 0.1s linear;
	transition: width .1s linear;
	@media (max-width: 1760px) { width: 81%; }
	@media (max-width: 1720px) { width: 82%; }
	@media (max-width: 1679px) { width: 83%; }
	@media (max-width: 1638px) { width: 84%; }
	@media (max-width: 1598px) { width: 85%; }
	@media (max-width: 1558px) { width: 86%; }
	@media (max-width: 1517px) { width: 87%; }
	@media (max-width: 1477px) { width: 88%; }
	@media (max-width: 1436px) { width: 89%; }
	@media (max-width: 1396px) { width: 90%; }
	@media (max-width: 1356px) { width: 91%; }
	@media (max-width: 1315px) { width: 92%; }
	@media (max-width: 1275px) { width: 93%; }
	@media (max-width: 1234px) { width: 94%; }
	@media (max-width: 1194px) { width: 95%; }
	@media (max-width: 1153px) { width: 96%; }
	@media (max-width: 1113px) { width: 97%; }
	@media (max-width: 1073px) { width: 98%; }
	@media (max-width: 1032px) { width: 99%; }
	@media (max-width: 991.9px) { width: 100%; }
}

.col-md-border:not(:last-child) {
	@media (min-width: 992px) {
		border-right: 1px solid $gray-light1;
	}
	@media (max-width: 991.9px) {
		border-bottom: 1px solid $gray-light1;
	}
}

.col-md-border + .col-md-border {
	@media (min-width: 992px) {
		margin-left: -1px;
	}
	@media (max-width: 991.9px) {
		margin-top: -1px;
	}
}

thead.thead-branded {
	tr th {
		color: $gray-mid3;
		font-weight: 400;
		font-size: 1.375em;
		border-top: none;
		border-bottom: 2px solid $brandcolor1;
		@media (max-width: 991.9px) {
			font-size: 1.25em;
		}
		@media (max-width: 767.9px) {
			font-size: 1.125em;
		}
	}
}

tbody.tbody-branded {
	tr td {
		line-height: 1;
		border-top: 1px dotted $gray-light3;
		@media (max-width: 991.9px) {
			font-size: 0.875em;
		}
		@media (max-width: 767.9px) {
			font-size: 0.75em;
		}
	}
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $brandcolor1-light;
}
