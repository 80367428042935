/* Project Teaser Cards */
.project-container {
	//margin: 0;
	padding: 0 2.0em 5.0em 2.0em;
}

.card-project-container {
	// padding: 0.625em;
}

.card-project-animate {
	border-radius: 0.625em;
	width: calc(100% - 1.25em);
	margin: 0.625em 0;
	.atropos-scale {
		border-radius: 0.625em;
		.atropos-rotate {
			border-radius: 0.625em;
			.atropos-inner {
				border-radius: 0.625em;
			}
		}
	}
}

.card-project {
	width: 100%;
	height: 100%;
	//width: calc(100% - 1.25em);
	background-color: $gray-light1;
	border-radius: 0.625em;
	overflow: hidden;
	@media (max-width: 991.9px) {
		margin: 0.625em 0;
	}
	&:hover {
		& > * {
			cursor: pointer;
		}
		.card-img {
			// filter: brightness(50%);
			filter: saturate(50%);
			filter: contrast(50%);
			transform: scale(1.25);
			opacity: 0.5;
		}
		.card-overlay-icon-container {
			opacity: 1.0;
			filter: blur(0px);
			-webkit-filter: blur(0px);
			-moz-filter: blur(0px);
			-ms-filter: blur(0px);
			-o-filter: blur(0px);
			.card-overlay-icon-background {
				backdrop-filter: blur(8px);
				-webkit-backdrop-filter: blur(8px);
				-moz-backdrop-filter: blur(8px);
				-ms-backdrop-filter: blur(8px);
				-o-backdrop-filter: blur(8px);
				.card-overlay-icon {
					color: $white;
				}
			}
		}
	}
	.card-img {
		width: 100% !important;
		height: 100% !important;
		min-width: 100%;
		min-height: 100%;
		border-radius: 0.625em;
		overflow: all;
		transition: all 2.5s ease-out;
		-webkit-transition: all 2.5s ease-out;
		-moz-transition: all 2.5s ease-out;
		-ms-transition: all 2.5s ease-out;
		-o-transition: all 2.5s ease-out;
		transition: all 2.5s ease-out;
		.img-zoom-hover {
			border-radius: 0.625em;
			width: 100% !important;
			height: 100% !important;
			min-width: 100%;
			min-height: 100%;
			display: none;
		}
	}
	.img-zoom-hover {
		border-radius: 0.625em;
	}
	.card-overlay {
		background: linear-gradient(180deg, rgba($gray-dark3,0) 0%, rgba($gray-dark3,0.95) 100%);
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 10.0em;
		border-radius: 0.625em;
		z-index: 2;
		pointer-events: none;
	}
	.card-body {
		margin: 0;
		padding: 0.5em 1.0em 0.5em 1.0em;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		font-size: 0.9em;
		line-height: 1.25;
		color: $white;
		.card-project-headline {
			color: $white;
		}
		.card-project-subheadline {
			font-size: 0.625em;
			font-weight: 700;
			letter-spacing: 0.25em;
			text-transform: uppercase;
			color: $white;
			margin-bottom: 0.3125em;
		}
	}
	.card-overlay-icon-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.625em;
		z-index: 3;
		pointer-events: none;
		opacity: 0;
		filter: blur(16px);
		-webkit-filter: blur(16px);
		-moz-filter: blur(16px);
		-ms-filter: blur(16px);
		-o-filter: blur(16px);
		transition: all 0.75s ease-out;
		-webkit-transition: all 0.75s ease-out;
		-moz-transition: all 0.75s ease-out;
		-ms-transition: all 0.75s ease-out;
		-o-transition: all 0.75s ease-out;
		transition: all 0.75s ease-out;
		transform: translateY(-1.0em);
		.card-overlay-icon-background {
			padding: 1.25em;
			border-radius: 1.25em;
			background-color: rgba($gray-dark3, 0.75);
			backdrop-filter: blur(4px);
			-webkit-backdrop-filter: blur(4px);
			-moz-backdrop-filter: blur(4px);
			-ms-backdrop-filter: blur(4px);
			-o-backdrop-filter: blur(4px);
			transition: all 1.0s ease-out;
			-webkit-transition: all 1.0s ease-out;
			-moz-transition: all 1.0s ease-out;
			-ms-transition: all 1.0s ease-out;
			-o-transition: all 1.0s ease-out;
			transition: all 1.0s ease-out;
			z-index: 4;
			.card-overlay-icon {
				text-align: center;
				font-family: $font-heading1;
				color: $black;
				font-size: 0.5em;
				text-transform: uppercase;
				letter-spacing: 0.25em;
				z-index: 5;
				transition: all 1.0s ease-out;
				-webkit-transition: all 1.0s ease-out;
				-moz-transition: all 1.0s ease-out;
				-ms-transition: all 1.0s ease-out;
				-o-transition: all 1.0s ease-out;
				transition: all 1.0s ease-out;
				.fac, .fa {
					font-size: 6.0em;
					padding-bottom: 0.125em;
					z-index: 6;
				}
			}
		}
	}
}

/* Main Projects Page */
.projects-filters-row {
	margin: 2.0em 0.5em 1.0em 0.5em;
	border-bottom: 1px solid $gray-white1;
	.projects-filters-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0 0.625em;
		h3 {
			font-family: $font-heading1;
			color: $gray-mid2;
			line-height: 1;
			margin-bottom: 0.25em;
		}
	}

	.projects-filters-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 0.625em;
		.projects-dropdown-toggle {
		}
	}
}

/* Project Detail Pages */
.project-body-container {
	padding: 0 2.5em 2.5em 2.5em;
	.project-container {
		padding: 1.875em 2.5em 0 2.5em;
	}
}

.project-subheadline-text {
	font-size: 1.5em;
	b {
		font-weight: 400;
		color: $gray-dark1;
	}
}

