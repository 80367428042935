header {
	position: relative;
	.header-top-container {
		background-color: $white;
		padding: 0;
		@media (max-width: 767.9px) {
		}
		.header-logo-container {
			padding: 1.25rem 1.875rem;
			text-align: left;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 991.9px) {
				padding: 1.25rem 0.9375rem;
			}
			@media (max-width: 767.9px) {
				padding: 1.25rem 0.625rem;
			}
			@media (max-width: 575.9px) {
				padding: 0.625rem;
			}
			.header-logo {
				display: inline-block;
				width: 140px;
				height: 114px;
				background: center center/contain no-repeat;
				text-indent: -9999px;
				@media (max-width: 991.9px) {
					width: 120px;
					height: 98px;
				}
				@media (max-width: 767.9px) {
					width: 100px;
					height: 82px;
				}
				@media (max-width: 575.9px) {
					// width: 120vw;
					// height: 30px;
				}
			}
		}
		.header-info-container {
			padding: 1.25em 1.875em;
			text-align: right;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			@media (max-width: 991.9px) {
				padding: 1.25rem 0.9375rem;
			}
			@media (max-width: 767.9px) {
				padding: 1.25rem 0.625rem;
			}
			@media (max-width: 575.9px) {
				padding: 0.625rem;
			}
			.btn {
				@media (max-width: 575.9px) {
					width: 100%;
				}
			}
		}
	}
	.header-nav-container {
		background-color: $brandcolor1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 0.875rem 0 1.875rem !important;
		@media (max-width: 991.99px) {
			justify-content: center;
			padding: 0 !important;
		}
		.navbar {
			padding: 0;
		}
	}
	.header-navbar-back-tab-left {
		display: block;
		position: absolute;
		z-index: 2;
		content: '';
		left: -2vw;
		top: 0;
		width: 15vw;
		height: 100%;
		background-color: $white;
		overflow-x: hidden;
		transform: skewX(-45deg);
		@media (max-width: 1400px) {
			left: -3vw;
		}
		@media (max-width: 991.9px) {
			display: none;
		}
		// @media (max-width: 767.9px) {
		// 	left: -5vw;
		// 	display: none;
		// }
		// @media (max-width: 575.9px) {
		// 	left: -6vw;
		// 	display: none;
		// }
	}
}
