/* Product Detail Pages */
.product-detail-info-container {
	margin: 0;
	padding: 0 0 0 1.875em;
	@media (max-width: 575.9px) {
		padding: 0;
	}
	.product-detail-thumb-container {
		padding: 0;
		@media (max-width: 575.9px) {
			padding: 2.5em 1.875em;
		}
	}
	.product-detail-attr-container {
		padding: 0;
		@media (max-width: 575.9px) {
			padding: 2.5em 0 0 0;
		}
	}
	.product-detail-attr-table {
		display: grid;
		grid-template-columns: repeat(2, minmax(min-content, max-content));
		grid-gap: 0.75em;
		justify-items: stretch;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			text-overflow: ellipsis;
		}
		.table-row-divider {
			grid-column: span 2;
			border-bottom: 1px solid $gray-light2;
			&:last-child {
				border-bottom: 1px solid $gray-mid2;
			}
		}
		.table-col {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			a {
				font-weight: 700;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					text-decoration: underline;
				}
			}
			.btn-outline-primary {
				font-size: 1.0em;
				padding: 0.3125em 1.0em 0.4375em 1.0em;
				color: $brandcolor1;
				letter-spacing: 0;
				text-transform: none;
				&:hover {
					color: $white;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
				}
				.fac {
					font-size: 1.3125em;
					transform: translateY(0.0625em);
				}
			}
		}
	}
}

.product-detail-label {
	padding: 0 0.625rem 0 0;
	color: $gray-mid2;
	font-size: 0.875em;
	font-weight: 700;
	// white-space: nowrap;
	@media (max-width: 575.9px) {
		// white-space: normal;
	}
}

.product-detail-description-container {
	padding: 1.25em 0;
	.product-detail-desc {
		padding-top: 0.625em;
	}
}

.product-detail-blocks-container {
	padding: 1.25em 0;
}

.product-detail-disclaimer-container {
	padding: 1.875rem 0 0 0;
	.product-detail-disclaimer {
		padding: 0.625rem 0 0 0;
		font-size: 0.875em;
		font-style: italic;
		letter-spacing: -0.005em;
	}
}