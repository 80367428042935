// News Article Teaser Cards
.news-card {
	width: calc(100% - 1.25em);
	margin: 0 0 2.5em 0;
	&:hover {
		.card-body {
			.news-article-teaser-headline h5 {
				color: $brandcolor1;
				text-decoration: none;
			}
		}
		.card-footer a {
			color: $brandcolor1;
			text-decoration: none;
			.fa, .fac {
				color: $brandcolor1-dark;
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.news-article-teaser-headline h5 {
				color: $brandcolor1-dark;
				text-decoration: none;
			}
		}
		.card-footer a {
			color: $brandcolor1-dark;
			text-decoration: none;
			.fa, .fac {
				color: $brandcolor1-dark;
			}
		}
	}
	.card-body {
		padding: 1.25em 0 0 0;
		.news-article-teaser-headline h5 {
			font-size: 1.25rem;
			color: $black;
			text-transform: none;
		}
		.news-article-teaser-copy {
			color: $gray-mid2;
			font-size: 0.9375em;
			line-height: 1.25;
		}
	}
	.card-footer {
		padding: 0.3125em 0;
	}
}

/* Main Articles Page */
.card-news-container {
	margin: 0;
	padding: 2.5em 0 5.0em 2.5em;
}

.news-categories-container {
	margin: 0;
	padding: 2.5em 0 0 0;
	.category-list-title {
		margin: 0 0 0.6125em 0;
		padding: 0 0 0.6125em 0;
		font-family: $font-heading1;
		color: $gray-mid2;
		font-size: 0.8175rem;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		border-bottom: 1px solid $black;
	}
	ul li {
		position: relative;
		display: block;
		font-family: $font-heading1;
		font-size: 0.75rem;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		&::after {
			content: "\f013";
			font-family: sans-serif;
			display: inline-block;
			font-size: 0.625em;
			font-weight: normal;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-rendering: auto;
			position: absolute;
			top: 1.5em;
			right: 0;
			color: $brandcolor1;
		}
		&.active {
			a {
				color: $brandcolor1;
			}
		}
		a {
			color: $black;
			&:hover {
				text-decoration: none;
				color: $brandcolor1 !important;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
				color: $brandcolor1-dark;
			}
		}
	}
}

/* News Detail Pages */
.news-body-container {
	padding: 0 2.5em 2.5em 2.5em;
	.news-container {
		padding: 1.875em 2.5em 0 2.5em;
		.video-thumb-container {
			margin: 0 0 2.5em 0;
			.news-video-featured-thumbnail {
				width: 100%;
				height: auto;
			}
		}
		.news-article-featured-image-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 2.5em 0;
			.news-article-featured-image {
				// width: 100%;
				// height: auto;
			}
		}
	}
}

.news-subheadline-text {
	font-size: 1.5em;
	b {
		font-weight: 400;
		color: $gray-dark1;
	}
}

