/* Common Agent Backend Classes */

/* Agent Buyer Search Page */
.buyer-search-layout-container {
	display: grid;
	grid-template-columns: 1fr minmax(17.5em, max-content);
	margin: 0;
	padding: 0;
	@media (max-width: 767.9px) {
		grid-template-columns: 100%;
	}
	.main-content-column {
		padding-bottom: 7.5em;
	}
	.search-filter-options-column {
		background-color: $gray-white2;
		@media (max-width: 767.9px) {
			order: -1;
		}
	}
}

/* Buyer Filters */
.buyer-filters-container {
	background-color: $brandcolor2;
	padding: 1.875rem 1.25rem;
	width: 100%;
	@media (max-width: 767.9px) {
		padding: 1.25rem 1.875rem;
	}
	.buyer-filters-title-row {
		@media (max-width: 767.9px) {
			display: none;
		}
		.buyer-filters-title {
			color: $white;
			font-size: 1.125em;
			// font-style: normal;
			// font-weight: 400;
			margin-bottom: 0.625em;
		}
	}
	label {
		color: $white;
		margin-bottom: 0.3125em;
	}
	.row {
		padding: 0.3125em 0;
	}
	.btn-filter-results-row {
		padding: 1.875em 0 0 0;
	}
	.btn-filter-clear-row {
		padding: 0.625em 0 0 0;
		.btn-filter-clear {
			padding: 0 0.125em;
			color: $white;
			font-weight: 400;
			text-transform: none;
			letter-spacing: 0;
			border-radius: 0;
			border-bottom: 1px solid $white;
		}
	}
}
.btn-buyer-filters-accordion-button {
	margin: 0;
	padding: 1.25rem 1.875rem;
	color: $white;
	background-color: $brandcolor2;
	border-radius: 0;
	border: none;
	// border-bottom: 1px solid $brandcolor1-light;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&[aria-expanded="true"] {
		.card-title {
			.fac { transform: rotate(180deg); }
		}
	}
	@media (max-width: 575.9px) {
		// margin-bottom: 1.25rem;
	}
	.fac {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $white;
	}
	.buyer-filters-title {
		display: inline-block;
		margin: 0;
		color: $white;
		font-size: 1.125em;
		line-height: 1;
		// margin-bottom: 0.625em;
	}
}

/* Buyer Filtered List */
.buyer-filters-chips-container {
	padding: 0 1.875em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.buyer-filters-list-container {
	padding: 1.25em 1.875em 2.5em 1.875em;
	font-size: 0.9375em;
	.buyer-filters-table {
		display: grid;
		grid-template-columns: 1fr 1fr minmax(min-content, .25fr) minmax(min-content, .33fr) 1fr minmax(min-content, max-content);
		grid-gap: 0.5em 0.5em;
		// justify-items: start;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			a {
				font-weight: 400;
				color: $black;
				text-decoration: none;
				&:hover {
					color: $brandcolor1-light;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
		.table-header {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
		}
		.table-header-divider {
			grid-column: span 6;
			border-bottom: 1px solid $gray-mid2;
			margin: 0.125em 0 0.3125em 0;
			height: 1px;
		}
		.table-row-divider {
			grid-column: span 6;
			padding: 0;
			border-bottom: 1px solid $gray-light2;
			margin: 0.125em 0;
			height: 1px;
			&:first-child {
				// border-bottom: 1px solid $gray-mid2;
				// margin: 0;
			}
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
		.table-col-link {
			height: 100%;
			a {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				position: relative;
				color: $brandcolor1;
				height: 100%;
			}
		}
		@media (max-width: 575.9px) {
			grid-template-columns: repeat(5, minmax(min-content, 1fr)) minmax(min-content, max-content);
			.table-col-header {
				display: none;
			}
			.table-col-company {
				grid-column: 1 / span 3;
			}
			.table-col-buyer {
				grid-column: 4 / span 3;
				justify-self: flex-end;
				justify-content: flex-end;
			}
			.table-col-state {
				grid-column: 1 / span 3;
			}
			.table-col-zip {
				grid-column: 4 / span 3;
				justify-self: flex-end;
				justify-content: flex-end;
			}
			.table-col-type {
				grid-column: 1 / span 5;
				justify-self: flex-start;
				justify-content: flex-start;
			}
		}
	}
}

/* Buyer Detail Layout */
.buyer-detail-layout-container {
	display: grid;
	grid-template-columns: 1fr minmax(17.5em, .20fr);
	margin: 0;
	padding: 0;
	@media (max-width: 767.9px) {
		grid-template-columns: 100%;
	}
	.main-content-column {
		padding-bottom: 2.5em;
	}
	.buyer-info-column {
		background-color: $gray-white2;
		@media (max-width: 767.9px) {
			order: 2;
		}
	}
}
