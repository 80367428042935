footer {
	@media (max-width: 575.9px) {
		font-size: 1.0625em;
	}
}

//Footer Top Rows
.footer-top {
	background-color: $gray-light1;
	.footer-top-inner {
		padding: 2.5em 0;
		text-align: center;
		.footer-links {
			text-align: left;
			padding: 0 1.875em;
			@media (max-width: 991.9px) {
				width: 50% !important;
				padding: 1.25em 1.875em;
			}
			@media (max-width: 767.9px) {
				width: 100% !important;
				text-align: center;
			}
			h6 {
				display: block;
				width: 100%;
				margin: 0;
				padding: 0;
				padding-bottom: 0.3125em;
				margin-bottom: 0.3125em;
				border-bottom: 1px solid $white;
			}
			ul.unstyled {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					border-bottom: 1px solid $gray-white2;
					//box-shadow: 0 1px 0 0 $gray-white3;
					&:first-child { padding-top: 0 }
					&:last-child { border-bottom: none; box-shadow: none; }
					a {
						padding-left: 0;
						padding-right: 0;
						// font-size: 0.9375em;
						font-weight: 400;
						@media (max-width: 767.9px) {
							font-size: 1.125rem;
						}
					}
				}
			}
		}
	}
}

//Footer Middle Rows
.footer-middle {
	background-color: $brandcolor1;
	.footer-middle-inner {
		padding: 1.875em;
		text-align: center;
		.row {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.footer-logo-container { 
			padding: 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
			.footer-logo {
				display: block;
				width: 140px;
				height: 114px;
				background: center center/contain no-repeat;
				text-indent: -9999px;
				margin: 0.625em;
				@media (max-width: 991.9px) {
					width: 120px;
					height: 98px;
					margin: 0.625em 0.625em 1.25em 0.625em;
				}
				@media (max-width: 767.9px) {
					width: 100px;
					height: 82px;
					margin: 0.625em 0.625em 1.25em 0.625em;
				}
				@media (max-width: 575.9px) {
					// width: 120vw;
					// height: 30px;
				}
			}
		}
		.footer-address {
			color: $white;
			line-height: 1;
			h6 {
				color: $white;
				line-height: 1;
				margin-bottom: 0.125em;
			}
			b {
				color: $brandcolor1-dark;
			}
			a {
				color: $white;
				border-bottom: 0.125em solid rgba($brandcolor1,0);
				&:hover {
					color: $white;
					text-decoration: none;
					border-bottom: 0.125em solid $brandcolor2-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
					text-decoration: none;
					border-bottom: 0.125em solid $white;
				}
			}
			@media (max-width: 767.9px) {
				padding: 1.25em 0 1.25em 0;
			}
		}
		.footer-phone {
			color: $white;
			line-height: 1;
			p {
				line-height: 1;
				margin: 0.125em 0;
			}
			a {
				color: $white;
				line-height: 1;
				font-weight: 700;
				border-bottom: 0.125em solid rgba($brandcolor1,0);
				&:hover {
					color: $white;
					text-decoration: none;
					border-bottom: 0.125em solid $brandcolor2-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
					text-decoration: none;
					border-bottom: 0.125em solid $white;
				}
			}
			@media (max-width: 767.9px) {
				padding: 1.25em 0 0 0;
			}
		}
	}
}

// Footer Bottom Rows
.footer-bottom {
	background-color: $brandcolor2;
	background-image: url('/assets/images/backgrounds/back-pattern-brandcolor2-waves.png');
	background-repeat: repeat;
	.footer-bottom-inner {
		padding: 1.875em;
		text-align: center;
		.row-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.row-middle {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.25em 0;
			// text-align: center;
		}
		.row-bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			// padding: 1.25em 0;
			// text-align: center;
		}
		.footer-body {
			color: $white;
			font-weight: 100;
			p {
				line-height: 1;
			}
		}
		.footer-copyright {
			color: $white;
			font-size: 0.875em;
			line-height: 1;
			padding: 0;
			@media (max-width: 991.9px) {
				padding: 0 0 0.625em 0;
			}
		}
		.footer-social-media {
				@media (max-width: 991.9px) {
					padding: 0.625em 0;
				}
				.footer-social-icon {
					color: $white;
					line-height: 1;
					&:hover {
						color: $white;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						text-decoration: none;
					}
				}
				.footer-social-desc {
					display: none;
				}
			}
		.footer-links {
			@media (max-width: 991.9px) {
				padding: 0 1.875em 0 0;
			}
			li.list-inline-item a {
				font-size: 1em;
				line-height: 1;
				margin: 0 0.3125em !important;
				padding: 0.5em 0 0.25em 0;
				color: $white;
				border-bottom: 0.25em solid rgba($brandcolor2, 0);
				&:hover {
					color: $white;
					text-decoration: none;
					border-bottom: 0.25em solid $brandcolor2-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
					text-decoration: none;
					border-bottom: 0.25em solid $white;
				}
			}
		}
		.footer-logo2-container {
			.footer-logo2 {
				width: 140px;
				@media (max-width: 991.9px) {
					width: 120px;
				}
				@media (max-width: 767.9px) {
					width: 100px;
				}
			}
		}
	}
}
