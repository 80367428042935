.link-text-nav, .link-text-subnav {
	color: $brandcolor1;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: clamp(0.875rem, calc(0.875rem + 0.25 * ((100vw - 36rem) / 39)), 1.125rem);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1-light;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
	}
	.fac {
		color: $brandcolor1;
	}
}

.link-text-subnav {
	color: $brandcolor1 !important;
	text-transform: none;
	letter-spacing: -0.0125em;
	&.active {
		color: $gray-mid3 !important;
	}
	&:hover {
		color: $brandcolor1-light !important;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-light;
	}
	.fac {
		font-size: 0.8em;
		color: $gray-mid2;
		&:hover {
			color: $brandcolor1-light;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1-dark;
		}
	}
}

.link-text-sm {
	color: $brandcolor1;
	font-family: $font-nav1;
	font-weight: 700;
	font-size: 0.875em;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1-light;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-light;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-dark;
		}
	}
	.fa, .fac {
		font-size: 0.8em;
		transform: translateY(-0.125em);
	}
}

.nav-tabs {
	position: relative;
	z-index: 50;
}


.navbar {
	margin: 0;
	padding: 0;
	.navbar-collapse {
		margin: 0;
		padding: 0;
		.navbar-nav {
			margin: 0;
			padding: 0;
			.nav-link, .dropdown-item {
				margin: 0 1.25em;
				padding: 1.25em 0 1.0em 0 !important;
				text-align: left;
				border-bottom: 0.25em solid rgba($brandcolor1,0);
				.nav-link-arrow {
					padding: 0 0 0 0.625em;
					color: $brandcolor1;
				}
				&.active  {
					background: none;
					border-bottom: 0.25em solid $gray-white0;
				}
			}
			.nav-item {
				margin: 0;
				&::marker {
					content: '';
					display: none;
				}
				.nav-link {
					color: $white;
					&:hover {
						color: $white;
						border-bottom: 0.25em solid rgba($brandcolor2-light, 1);
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						border-bottom: 0.25em solid $white;
					}
				}
			}
			.dropdown {
				.dropdown-toggle {
					&:hover {
						color: $white !important;
						border-bottom: 0.25em solid rgba($brandcolor2-light, 1);
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white !important;
						border-bottom: 0.25em solid $white;
					}
				}
				.dropdown-menu {
					background-color: $white;
					margin: 0;
					padding: 0.3125em 0.3125em;
					border-radius: 0;
					border: none;
					box-shadow:
						0px 2.8px 2.2px rgba(0, 0, 0, 0.014),
						0px 6.7px 5.3px rgba(0, 0, 0, 0.02),
						0px 12.5px 10px rgba(0, 0, 0, 0.025),
						0px 22.3px 17.9px rgba(0, 0, 0, 0.03),
						0px 41.8px 33.4px rgba(0, 0, 0, 0.036),
						0px 100px 80px rgba(0, 0, 0, 0.05);
					.dropdown-item {
						margin: 0 0.9375em !important;
						padding: 0.625em 0 !important;
						color: $brandcolor1;
						width: auto;
						border-bottom: 1px solid $gray-white2;
						&:first-child { padding-top: 0 }
						&:last-child { border-bottom: none; }
						&:hover {
							color: $brandcolor1-light;
							background: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $brandcolor1-dark !important;
							background: none !important;
						}
					}
				}
			}
			/*
			li {
				display: block;
				transition-duration: 0.5s;
				&:hover {
					cursor: default;
					&.pointer {
						cursor: pointer;
					}
				}
			}
			ul li ul {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				transition: all 0.5s ease-out;
				display: none;
				padding-top: 0.625em;
				a {
					color: $white;
					background-color: $brandcolor1;
					padding: 0.625em 0 !important;
					&:hover {
						color: $white;
					}
				}
			}
			ul li:hover > ul, ul li ul:hover {
				visibility: visible;
				opacity: 1;
				display: block;
			}
			ul li ul li {
				clear: both;
				width: 100%;
			}
			*/
		}
	}
	.navbar-toggler {
		margin-left: auto;
		margin-right: auto;
		padding: 0.625rem 0;
		font-family: $font-nav1;
		font-size: 1.0rem;
		line-height: 1;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		color: $white;
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media (max-width: 991.9px) {
	.navbar-expand-lg {
		.navbar-collapse {
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw !important;
			margin: 0;
			padding: 0;
			background-color: $brandcolor1-dark !important;
			position: relative;
			z-index: 2;
			.navbar-nav {
				border: none;
				float: none;
				.nav-link, a.dropdown-item {
					margin: 0;
					padding: 1.125rem 1.875rem !important;
					font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					text-align: center;
				}
				.nav-item {
					margin: 0;
					border-bottom: 1px solid $brandcolor1;
					.nav-link {
						border-bottom: none;
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
						&.active  {
							border-bottom: none;
						}
					}
				}
				.dropdown {
					.dropdown-toggle {
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
					}
					.dropdown-menu {
						padding: 0;							
						a.dropdown-item {
							margin: 0 !important;
							padding: 0.9375rem 1.875rem !important;
							color: $white !important;
							background-color: $brandcolor1;
							border-bottom: 1px solid $brandcolor1-dark;
							&:last-child { 
								border-bottom: none;
							}
							&:hover {
								background-color: $brandcolor1-white1;
								border-bottom: initial;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								background-color: $brandcolor1-white3;
								border-bottom: initial;
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 767.9px) {
	.navbar-expand-md {
		.navbar-collapse {
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw !important;
			margin: 0;
			padding: 0;
			background-color: $brandcolor1-dark !important;
			position: relative;
			z-index: 2;
			.navbar-nav {
				border: none;
				float: none;
				.nav-link, a.dropdown-item {
					margin: 0;
					padding: 1.125rem 1.875rem !important;
					font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					text-align: center;
				}
				.nav-item {
					margin: 0;
					border-bottom: 1px solid $brandcolor1;
					.nav-link {
						border-bottom: none;
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
						&.active  {
							border-bottom: none;
						}
					}
				}
				.dropdown {
					.dropdown-toggle {
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
					}
					.dropdown-menu {
						padding: 0;							
						a.dropdown-item {
							margin: 0 !important;
							padding: 0.9375rem 1.875rem !important;
							color: $white !important;
							background-color: $brandcolor1;
							border-bottom: 1px solid $brandcolor1-dark;
							&:last-child { 
								border-bottom: none;
							}
							&:hover {
								background-color: $brandcolor1-white1;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								background-color: $brandcolor1-white3;
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 575.9px) {
	.navbar-expand-sm {
		.navbar-collapse {
			position: absolute;
			top: 0;
			right: 0;
			width: 100vw !important;
			margin: 0;
			padding: 0;
			background-color: $brandcolor1-dark !important;
			position: relative;
			z-index: 2;
			.navbar-nav {
				border: none;
				float: none;
				.nav-link, a.dropdown-item {
					margin: 0;
					padding: 1.125rem 1.875rem !important;
					font-size: clamp(1.125rem, calc(1.125rem + 0.25 * ((100vw - 36rem) / 39)), 1.375rem);
					text-align: center;
				}
				.nav-item {
					margin: 0;
					border-bottom: 1px solid $brandcolor1;
					.nav-link {
						border-bottom: none;
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
						&.active  {
							border-bottom: none;
						}
					}
				}
				.dropdown {
					.dropdown-toggle {
						&:hover {
							border-bottom: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							border-bottom: none;
						}
					}
					.dropdown-menu {
						padding: 0;							
						a.dropdown-item {
							margin: 0 !important;
							padding: 0.9375rem 1.875rem !important;
							color: $white !important;
							background-color: $brandcolor1;
							border-bottom: 1px solid $brandcolor1-dark;
							&:last-child { 
								border-bottom: none;
							}
							&:hover {
								background-color: $brandcolor1-white1;
							}
							&:active, &:focus, &:not(:disabled):not(.disabled):focus {
								background-color: $brandcolor1-white3;
							}
						}
					}
				}
			}
		}
	}
}

.navbar-brandcolor2 {
	.navbar-collapse {
		.navbar-nav {
			.nav-link, .dropdown-item {
				border-bottom: 0.25em solid rgba($brandcolor2,0);
				.nav-link-arrow {
					color: $brandcolor2;
				}
			}
			.nav-item {
				.nav-link {
					&:hover {
						border-bottom: 0.25em solid rgba($brandcolor2-light, 1);
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						border-bottom: 0.25em solid $white;
					}
				}
			}
		}
	}
}

@media (max-width: 991.9px) {
	.navbar-expand-lg.navbar-brandcolor2 {
		.navbar-nav {
			.nav-item {
				background-color: $brandcolor2-dark;
				border-bottom: 1px solid $brandcolor2;
				.nav-link {
				}
			}
			.dropdown {
				.dropdown-menu {						
					a.dropdown-item {
						background-color: $brandcolor2;
						border-bottom: 1px solid $brandcolor2-dark;
						&:last-child { 
							border-bottom: none;
						}
						&:hover {
							background-color: $brandcolor2;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							background-color: $brandcolor2;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 767.9px) {
	.navbar-expand-md.navbar-brandcolor2 {
		.navbar-nav {
			.nav-item {
				background-color: $brandcolor2-dark;
				border-bottom: 1px solid $brandcolor2;
				.nav-link {
				}
			}
			.dropdown {
				.dropdown-menu {						
					a.dropdown-item {
						background-color: $brandcolor2;
						border-bottom: 1px solid $brandcolor2-dark;
						&:last-child { 
							border-bottom: none;
						}
						&:hover {
							background-color: $brandcolor2;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							background-color: $brandcolor2;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 575.9px) {
	.navbar-expand-sm.navbar-brandcolor2 {
		.navbar-nav {
			.nav-item {
				background-color: $brandcolor2-dark;
				border-bottom: 1px solid $brandcolor2;
				.nav-link {
					&:hover {
						border-bottom: 1px solid $brandcolor2-dark;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						border-bottom: 1px solid $brandcolor2-dark;
					}
				}
			}
			.dropdown {
				.dropdown-menu {						
					a.dropdown-item {
						background-color: $brandcolor2;
						border-bottom: 1px solid $brandcolor2-dark;
						&:last-child { 
							border-bottom: none;
						}
						&:hover {
							background-color: $brandcolor2;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							background-color: $brandcolor2;
						}
					}
				}
			}
		}
	}
}
