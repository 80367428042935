#questions {
	h2, h3, h4 {
		margin-bottom: 1.25rem;
	}
	p {
		font-size: 1.25rem;
		line-height: 1;
	}
}

#red-zone {
	.block-text-image-body-column {
		padding: 7.25em 1.875em 7.25em 3.75em !important;
		@media (max-width: 991.9px) {
			padding: 1.875em 1.875em 7.25em 1.875em !important;
		}
		.block-text-image-branding-image-container {
			justify-content: flex-start;
			@media (max-width: 991.9px) {
				justify-content: center;
			}
		}
		.btn {
			padding-left: 3.75em;
			padding-right: 3.75em;
		}
	}
	.img-background-fill {
		transform: skew(-4deg) translateX(2vw);
		overflow-x: hidden;
		height: auto;
		@media (max-width: 1200px) {
			transform: skew(-5deg) translateX(3vw);
		}
		@media (max-width: 991.9px) {
			transform: skew(0deg) translateX(0);
		}
		img, .image-icon-image {
			max-height: 75vh;
		}
	}
}

#sonata {
	h4, h5 {
		margin: 0.625rem 0 0.12rem 0;
		font-family: $font-heading1;
		color: $brandcolor2;
		letter-spacing: -0.05rem;
	}
	p {
		font-size: 0.9375rem;
	}
}

#inthetrenches {
	h3 {
		margin: 0.625rem 0 0.12rem 0;
		font-family: $font-heading1;
		color: $brandcolor2;
		letter-spacing: -0.05rem;
	}
	p {
		font-size: 0.9375rem;
	}
	.block-container-video {
		@media (max-width: 575.9px) {
			padding-bottom: 1.25rem !important;
		}
	}
}

#suggestions {
	.row {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		align-content: flex-end;
	}
	#suggestions-photo {
		padding: 0;
		.block-single-image-container {
			position: relative;
			.img-overlay-sections {
				top: initial;
				right: initial;
				left: 0;
				bottom: 0;
				.image-icon-image {
					//min-width: 20rem;
					max-height: 35.0rem;
					@media (max-width: 767.9px) {
						border-bottom: 1px solid $gray-light2;
					}
					@media (max-width: 575.9px) {
						max-width: 75%;
					}
				}
			}
		}
	}
	#suggestions-text {
		padding: 6.25rem 0;
		p {
			margin-bottom: 0;
			line-height: 1;
		}
		p.lead {
			font-size: 1.125rem;
			font-weight: 400;
			line-height: 1.5;
			margin-bottom: 1.25rem;
		}
		@media (max-width: 767.9px) {
		}
		@media (max-width: 575.9px) {
			padding: 0 1.25rem 1.25rem 1.25rem;
		}
	}
	#suggestions-form {
		padding: 6.25rem 1.875rem 6.25rem 1.875rem;
		@media (max-width: 767.9px) {
			padding: 0 1.875rem 6.25rem 1.875rem;
		}
	}
}

#sales-rep {
}

#grower-financing, #prepay-incentive {
	h3, h4 {
		margin: 0.625rem 0 0.12rem 0;
		font-family: $font-heading1;
		color: $brandcolor2;
		letter-spacing: -0.05rem;
	}
	p {
		font-size: 1.125rem;
	}
}