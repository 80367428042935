.carousel {
	width: 100%;
	height: auto;
	position: relative;
	z-index: 1;
	min-height: 70vh;
	@media (max-width: 767.9px) {
		min-height: 60vh;
	}
	@media (max-width: 575.9px) {
		min-height: 50vh;
	}
}

.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	// height: calc(100vw * 0.5625);
	position: relative;
	z-index: 1;
	height: 70vh;
	@media (max-width: 767.9px) {
		height: 60vh;
	}
	@media (max-width: 575.9px) {
		height: 50vh;
	}
}

.carousel-image {
	width: 100%;
	height: auto;
	visibility: hidden;
}

.carousel-overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	background-color: rgba($gray-dark3, 0.33);
	&::after {
		background: $brandcolor2;
		overflow-x: hidden;
		content: '';
		display: inline-block;
		width: 20vw;
		height: 5vh;
		right: -2.5vh;
		position: absolute;
		z-index: 1;
		-webkit-backface-visibility: hidden; // for Chrome Windows
		bottom: -1vh;
		transform: skewX(-45deg);
	}
}

.carousel-caption {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: auto;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	.banner-caption-headline, .banner-caption-subheadline {
		margin: 0;
		padding: 0;
		font-family: $font-heading1;
		letter-spacing: -0.1rem !important;
		text-transform: uppercase;
		font-style: italic;
		line-height: 0.72;
		font-size: clamp(3.625rem, calc(3.625rem + 1.5 * ((100vw - 36rem) / 39)), 5.125rem);
		// font-size: calc(3.625rem + 1.5 * ((100vw - 36em) / 39));
		// font-size: -webkit-calc(3.625rem + 1.5 * ((100vw - 36em) / 39));
		// font-size: -moz-calc(3.625rem + 1.5 * ((100vw - 36em) / 39));
		// @media (max-width: 575.9px) {
		// 	font-size: 3.625rem;
		// }
		// @media (min-width: 1200px) {
		// 	font-size: 5.125rem;
		// }
	}
	.banner-caption-headline {
		color: $white;
		display: inline;
	}
	.banner-caption-subheadline {
		margin: 0 0 0 1.9rem;
		display: inline;
		color: $white;
		opacity: 0.8;
		@media (max-width: 1430px) {
			margin: 0 0 0 1.0rem;
		}
	}
	.banner-caption-icon-container {
		width: 20rem;
		height: auto;
		transform: translateX(-2rem);
	}

	hr.caption-divider {
		overflow: visible; /* For IE */
		padding: 0;
		margin: 0 0 0.625em 0;
		border: none;
		border-top: 1px solid $brandcolor1;
		color: $white;
		text-align: right;
	}
	.caption-body {
		padding-top: 0;
		p {
			line-height: 1;
		}
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 1.25em;
	background-image: none;
  border-radius: 50%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
}

.carousel-control-prev-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: $font-icons1; font-weight: 900; content: "\f053";
	color: $white;
	font-size: 2.625em;
}

.carousel-control-next-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: $font-icons1; font-weight: 900; content: "\f054";
	color: $white;
	font-size: 2.625em;
}

#banner-down-arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	text-align: center;
	pointer-events: none;
}

#banner-down-arrow a {
	position: absolute;
	bottom: 2.0em;
	left: 0;
	width: 100vw;
	-webkit-animation: anim-banner-down-arrow 2s infinite;
	animation: anim-banner-down-arrow 2s infinite;
	.fa, .fac {
		font-size: 2.0em;
		color: $brandcolor1;
		&:hover {
			color: $brandcolor1-light;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1-dark;
		}
	}
}

@-webkit-keyframes anim-banner-down-arrow {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(2.0em);
		opacity: 0;
	}
}

@keyframes anim-banner-down-arrow {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(2.0em);
		opacity: 0;
	}
}