/* Customer Account Navigation */
.customer-account-navigation-bar {
	margin: 0;
	padding: 0;
	background-color: $brandcolor2;
	@media (max-width: 767.9px) {
		background-color: $gray-light1;
	}
	.customer-account-nav-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		margin: 0;
		padding: 0 1.875rem;
		@media (max-width: 767.9px) {
			justify-content: center;
			flex-wrap: wrap;
			padding: 0;
			.navbar {
				width: 100%;
				background-color: $brandcolor2;
			}
		}
	}
	.quote-customer-detail-button {
		margin: 0;
		padding: 0.9375rem 3.75rem;
		@media (max-width: 767.9px) {
			margin: 0.625rem 1.875rem;
			width: 100%;
		}
		&[data-order-item-count="0"],
		[data-order-item-count="0"] {
			display: none;
		}
	}
}


/* Customer Account Dashboard Layout */
.customer-dashboard-layout-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media (max-width: 767.9px) {
		.customer-dashboard-overview-container {
			order: 3;
			padding-bottom: 6.25rem;
		}
	}
}
.customer-dashboard-links-container, .customer-dashboard-sales-rep-container {
	padding-top: 1.875rem;
	@media (max-width: 767.9px) {
		padding-top: 0;
	}
}



/* Customer Account Dashboard Links */
.customer-dashboard-links {
	padding: 1.875rem 0 1.875rem 0.3125rem !important;
	@media (max-width: 767.9px) {
		padding: 0 !important;
	}
	.customer-dashboard-link-item {
		.customer-dashboard-link {
			display: inline-block;
			padding: 0.3125rem 0 !important;
			font-size: 1.375rem;
			text-transform: initial;
			letter-spacing: initial;
		}
	}
}



/* Your Sales Rep Small */
.sales-rep-sm-img-container {
	.sales-rep-sm-img {
		position: relative;
		overflow: hidden;
		z-index: 0;
		&:hover {
			.sales-rep-sm-back {
				opacity: 1.0;
				transform: scale(1.0);
				-webkit-filter: blur(0);
				-moz-filter: blur(0);
				-ms-filter: blur(0);
				-o-filter: blur(0);
				filter: blur(0);
			}
		}
		.sales-rep-sm-back {
			opacity: 0.8;
			transform: scale(1.25);
			-webkit-filter: blur(2px);
			-moz-filter: blur(2px);
			-ms-filter: blur(2px);
			-o-filter: blur(2px);
			filter: blur(2px);
			-webkit-transition: all 3.0s ease-out;
			-moz-transition: all 3.0s ease-out;
			-ms-transition: all 3.0s ease-out;
			-o-transition: all 3.0s ease-out;
			transition: all 3.0s ease-out;
		}
		.sales-rep-sm-portait {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			overflow: hidden;
			z-index: 2;
			pointer-events: none;
		}
	}
}

.sales-rep-sm-text-container {
 .people-title {
		margin: 0 0 0.0625rem 0;
		// font-family: $font-body1;
		// font-size: 3.5rem;
		// line-height: 0.875;
		// color: $brandcolor2;
	}
	.people-title-position {
		margin: 0 0 0.625rem 0;
		// font-size: 2.5rem;
		// color: $gray-dark2;
		// font-weight: 400;
	}
	.people-contact-info {
		margin: 0;
		padding: 0.3125rem 0 0 0 !important;
		// font-size: 1.5rem;
		p {
			margin: 0;
			padding: 0;
			font-family: $font-heading1;
			line-height: 1.25;
			b {
				color: $gray-mid2;
			}
		}
	}
}




/* Your Sales Rep Large */
.sales-rep-lg-container {
}
.sales-rep-lg-img-container {
	.sales-rep-lg-img {
		position: relative;
		overflow: hidden;
		z-index: 0;
		&:hover {
			.sales-rep-lg-back {
				opacity: 1.0;
				transform: scale(1.0);
				-webkit-filter: blur(0);
				-moz-filter: blur(0);
				-ms-filter: blur(0);
				-o-filter: blur(0);
				filter: blur(0);
			}
		}
		.sales-rep-lg-back {
			opacity: 0.8;
			transform: scale(1.25);
			-webkit-filter: blur(2px);
			-moz-filter: blur(2px);
			-ms-filter: blur(2px);
			-o-filter: blur(2px);
			filter: blur(2px);
			-webkit-transition: all 3.0s ease-out;
			-moz-transition: all 3.0s ease-out;
			-ms-transition: all 3.0s ease-out;
			-o-transition: all 3.0s ease-out;
			transition: all 3.0s ease-out;
		}
		.sales-rep-lg-portait {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			overflow: hidden;
			z-index: 2;
			pointer-events: none;
		}
	}
}

.sales-rep-lg-text-container {
	@media (max-width: 575.9px) {
		padding: 1.875rem;
	}
 .people-title {
		margin: 0 0 0.0625rem 0;
		font-family: $font-body1;
		font-size: 3.5rem;
		line-height: 0.875;
		color: $brandcolor2;
	}
	.people-title-position {
		margin: 0 0 0.625rem 0;
		font-size: 2.5rem;
		color: $gray-dark2;
		font-weight: 400;
	}
	.people-contact-info {
		margin: 0;
		padding: 0.3125rem 0 0 0 !important;
		font-size: 1.5rem;
		p {
			margin: 0;
			padding: 0;
			font-family: $font-heading1;
			line-height: 1.25;
			b {
				color: $gray-mid2;
			}
		}
	}
}
