/* People Cards */
.people-cards-container {
	padding: 1.0em 1.875rem 5.0em 1.875rem;
	.people-category-title-row {
		padding: 2.5rem 0 1.25rem 0;
		.people-category-title-container {
			@media (max-width: 767.9px) {
				margin: 0;
				padding: 0;
			}
		}
	}
	.people-category-cards-row {
		padding: 0;
		.people-card-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 3.75rem;
			@media (max-width: 767.9px) {
				grid-template-columns: 1fr 1fr;
			}
			@media (max-width: 575.9px) {
				grid-template-columns: 1fr;
			}
		}
	}
}
.people-card {
	border-radius: 0;
	// width: 100%;
	&:hover {
		& > * {
			// cursor: pointer;
		}
		.card-img {
			.card-img-back {
				opacity: 1.0;
				transform: scale(1.0);
				filter: blur(0);
			}
		}
	}
	.card-img {
		// top: initial;
		border-radius: 0;
		z-index: 0;
		overflow: hidden;
		.card-img-back {
			opacity: 0.8;
			transform: scale(1.25);
			filter: blur(2px);
			transition: all 3.0s ease-out;
			&.img-load-wait {
				opacity: 0.0;
				transform: scale(1.0);
			}
		}
		.card-img-overlay {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			overflow: hidden;
			z-index: 2;
			pointer-events: none;
		}
	}
	.card-img-overlay {
		border-radius: 0;
		pointer-events: none;
		z-index: 3;
	}
	.card-body {
		margin: 0;
		padding: 1.25rem 0 0 0;
		.card-people-title {
			margin: 0 0 0.125rem 0;
			font-family: $font-body1;
			color: $brandcolor2;
		}
		.card-people-title-position {
			margin: 0 0 0.125rem 0;
			color: $gray-dark2;
			font-weight: 400;
		}
	}
	.card-footer {
		margin: 0;
		padding: 0.3125rem 0 0 0 !important;
		p {
			margin: 0;
			padding: 0;
			font-family: $font-heading1;
			line-height: 1.25;
			b {
				color: $gray-mid2;
			}
		}
	}
}

//Main People Detail Page
.people-header-container {
	position: relative;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.people-header-container-overlay-1 {
		background: linear-gradient(180deg, rgba($gray-dark1,0.8) 0%, rgba($gray-dark1,0.25) 33%, rgba($gray-dark1,0.25) 66%, rgba($gray-dark1,0.8) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.people-header-container-overlay-2 {
		background: radial-gradient(circle, rgba($gray-dark1,0.25) 0%, rgba($gray-dark1,0.6) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.people-header-text-overlay {
		position: relative;
		z-index: 3;
		.people-header-info-container {
			color: $white;
			a {
				color: $white;
			}
			.people-header-divider {
				width: 6.0em;
				border-top: 1px solid $brandcolor1;
				color: $brandcolor1;
				margin-left: 0;
			}
			p {
				font-size: 1.5em;
				color: $gray-light2;
				margin-bottom: 0.625em;
				line-height: 1;
				a {
					color: $white;
				}
			}
			.btn-sm {
				font-size: 1.0em;
				padding: 0.625em 1.25em;
			}
		}
	}
}
.people-container {
	padding: 5.0em 0;
	ul {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			border-bottom: 1px solid $gray-light1;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				border-bottom: none;
			}
			&::marker {
				content: "";
				display: none;
			}
			b {
				color: $black;
				font-weight: 700;
			}
			&::before {
				content: "";
				display: none;
			}
		}
	}
}
