.progress-bar {
	background: $gray-white1;
	background: linear-gradient(180deg, rgba($gray-white2,1) 0%, rgba($gray-white2,1) 40%, rgba($white,1) 80%, rgba($gray-white1,1) 100%);
	border: 1px solid $gray-light2;
	-webkit-box-shadow: inset 0 0 10px rgba($gray-dark2, 0.25) !important;
	box-shadow: inset 0 0 0.625em rgba($gray-dark2, 0.25)  !important;
	margin: 0;
	height: 1.25em;
	border-radius: 0.625em;
}
.progress-bar-inner {
	margin: 0.125em 0.125em;
	height: 100%;
	background: $brandcolor2;
	background: linear-gradient(180deg, rgba($brandcolor2,1) 0%, rgba($brandcolor2-light,1) 20%, rgba($brandcolor2,1) 40%, rgba($brandcolor2-dark,1) 80%, rgba($brandcolor2,1) 100%);
	border-bottom: 1px solid $brandcolor2;
	border-top: 1px solid $brandcolor2-dark;
	border-left: 1px solid $brandcolor2-light;
	border-right: 2px solid $brandcolor2;
	border-radius: 0.5em;
	border-top-left-radius: 0.625em;
	border-bottom-left-radius: 0.625em;
	border-top-right-radius: 0.25em;
	border-bottom-right-radius:  0.25em;
	-webkit-box-shadow: 0 0 0.125em rgba($gray-dark2, 0.5) !important;
	box-shadow: 0 0 0.125em rgba($gray-dark2, 0.5)  !important;
	-webkit-transition: all 1.0s ease-out;
	-moz-transition: all 1.0s ease-out;
	-ms-transition: all 1.0s ease-out;
	-o-transition: all 1.0s ease-out;
	transition: all 1.0s ease-out;
}