.block-image-slider {
	min-height: auto;
	&.loading {
		//min-height: 66vh;
	}
	width: 100vw;
	//** height: calc(100vh - 10em);
	.block-image-slider-wrapper {
		.block-image-slide {
			display: inline-block;
			width: auto;
			// height: 100vh; // Some browsers, including Safari need a height in this context
			//max-width: 100vw;
			//max-height: calc(100vh - 10em);
			overflow: hidden;
			.block-image-slide-image {
				// width: auto;
				// height: 100%;
				max-width: 100vw;
				max-height: calc(100vh - 10rem);
				opacity: 0.75;
				border: none;
				-webkit-filter: blur(0px) grayscale(0%) contrast(50%);
				-moz-filter: blur(0px) grayscale(0%) contrast(50%);
				-ms-filter: blur(0px) grayscale(0%) contrast(50%);
				-o-bfilter: blur(0px) grayscale(0%) contrast(50%);
				filter: blur(0px) grayscale(0%) contrast(50%);
				-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-o-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				transition: filter 1.0s ease-out, opacity 1.0s ease-out;
			}
		}
		.swiper-slide-active {
			.block-image-slide-image {
				opacity: 1.0;
				border: none;
				-webkit-filter: blur(0) grayscale(0) contrast(100%);
				-moz-filter: blur(0) grayscale(0) contrast(100%);
				-ms-filter: blur(0) grayscale(0) contrast(100%);
				-o-bfilter: blur(0) grayscale(0) contrast(100%);
				filter: blur(0) grayscale(0) contrast(100%);
			}
		}
	}
}

.swiper-button-next, .swiper-button-prev {
	&::after {
		font-size: 1.75em;
		color: $brandcolor1-light;
	}
	&:hover {
		&::after {
			color: $brandcolor1;
		}
	}
	&:focus-visible {
		outline: none;
	}
}

.swiper-pagination {
	.swiper-pagination-bullet {
		width: 0.625em;
		height: 0.625em;
		border-radius: 50%;
		background: none;
		border: 0.125em solid $white;
		opacity: 1.0;
		margin: 0 0.1em !important;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		// -webkit-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -moz-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -ms-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -o-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// transition: background-color 0.5s ease-out, border 0.5s ease-out;
		&:hover {
			background-color: $white;
			border: 0.3125em solid $white;
		}
	}
	.swiper-pagination-bullet-active {
		background-color: $brandcolor2;
		border: 0.125em solid $brandcolor2;
	}
}
