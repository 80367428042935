.page-title-full-width {
	position: absolute;
	background-color: $white;
	left: 0;
	bottom: 0;
	width: 100%;
	height: auto;
	z-index: 0;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center top;
	background-attachment: fixed;
	filter: blur(4px) grayscale(25%);
	-webkit-filter: blur(4px) grayscale(25%);
	-moz-filter: blur(4px) grayscale(25%);
	-o-filter: blur(4px) grayscale(25%);
	opacity: 0.25;
	overflow: hidden;
}

.page-title-back {
	// background-color: $gray-dark3;
	position: relative;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center top;
	background-attachment: fixed;
	width: 100%;
	height: auto;
	max-width: 2200px;
	z-index: 2;
	margin: 0;
	padding: 0;
	overflow: visible;
}

.page-title-image {
	width: 100%;
	height: auto;
}

.page-title-overlay {
	// background: linear-gradient(180deg, rgba($white,0.8) 0%, rgba($white,0.2) 33%, rgba($white,0) 66%, rgba($white,0.8) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

h1.page-title {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.page-title-container {
	padding: 2.5em 1.875em 1.875em 1.875em;
	@media (max-width: 575.9px) {
		padding: 1.875em 1.875em 0.75rem 1.875em;
	}
}

.page-subtitle-container {
	padding: 0 1.5em 1.875em 1.5em;
}

.page-title-backlink {
	display: inline-block;
	margin: 0 0 1.25rem 0;
}

.product-search-menu-container {
	background-color: $brandcolor2;
	background: linear-gradient(90deg, rgba($brandcolor2,0) 50%, rgba($brandcolor2,0.75) 75%, rgba($brandcolor2,1) 100%), url('/assets/images/backgrounds/back-pattern-brandcolor2-waves.png');
	background-repeat: repeat;
	background-position: center center;
	background-attachment: fixed;
	padding: 0.625rem 0.9375rem;
	z-index: 11;
	.product-search-bar-container {
		display: flex;
		justify-content: stretch;
		align-items: center;
		.form-inline {
			width: 100%;
			.form-group {
				width: 100%;
				.product-search-input {
					width: 100%;
					padding: 0.9375rem;
					border-radius: 2rem !important;
					border: none;
					text-indent: 1.25rem;
				}
				.fa, .fac {
					display: block;
					position: absolute;
					top: 33%;
					left: 1.625rem;
					width: 1.25rem;
					height: 1.625rem;
					z-index: 2;
					line-height: 1;
					text-align: center;
					pointer-events: none;
					@media (max-width: 575.9px) {
						top: 33%;
						left: 1.625rem;
					}
				}
			}
		}
	}
	.product-search-buttons-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.quote-customer-detail-button {
			padding: 0.9375rem 3.75rem;
			@media (max-width: 575.9px) {
				width: 100%;
				margin: 0 0 0.625rem 0;
			}
			&[data-order-item-count="0"],
			[data-order-item-count="0"] {
				display: none;
			}
		}
	}
}
