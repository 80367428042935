.quote-status-chip {
	display: inline-block;
	background-color: $brandcolor1-white3;
	padding-right: 0.9375em;
	border-radius: 50em;
	font-family: $font-heading1;
	font-size: 0.9375em;
	font-weight: 400;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	.fa, .fac {
	}
	.fac-stack-1x {
		font-size: 1.125rem;
		color: $white;
	}
	.fac-stack-2x {
		color: $brandcolor1-light;
	}
}

.quote-status-chip-draft {
	background-color: $quote-status-draft-white;
	.fac-stack-2x {
		color: $quote-status-draft-dark;
	}
}

.quote-status-chip-new {
	background-color: $quote-status-new-white;
	.fac-stack-2x {
		color: $quote-status-new-dark;
	}
}

.quote-status-chip-quoting {
	background-color: $quote-status-quoting-white;
	.fac-stack-2x {
		color: $quote-status-quoting-dark;
	}
}

.quote-status-chip-open {
	background-color: $quote-status-open-white;
	.fac-stack-2x {
		color: $quote-status-open-dark;
	}
}

.quote-status-chip-accepted {
	background-color: $quote-status-accepted-white;
	.fac-stack-2x {
		color: $quote-status-accepted-dark;
	}
}

.quote-status-chip-declined {
	background-color: $quote-status-declined-white;
	.fac-stack-2x {
		color: $quote-status-declined-dark;
	}
}

.quote-status-chip-closed {
	background-color: $quote-status-closed-white;
	.fac-stack-2x {
		color: $quote-status-closed-dark;
	}
}

.quote-status-chip-expired {
	background-color: $quote-status-expired-white;
	.fac-stack-2x {
		color: $quote-status-expired-dark;
	}
}

.quote-status-chip-unknown {
	background-color: $quote-status-unknown-white;
	.fac-stack-2x {
		color: $quote-status-unknown-dark;
	}
}

.quote-cta-message {
	display: none;
}
[data-order-status="draft"] .quote-cta-message-draft,
[data-order-status="new"] .quote-cta-message-new,
[data-order-status="quoting"] .quote-cta-message-quoting,
[data-order-status="open"] .quote-cta-message-open,
[data-order-status="agreed"] .quote-cta-message-agreed,
[data-order-status="accepted"] .quote-cta-message-accepted,
[data-order-status="declined"] .quote-cta-message-declined,
[data-order-status="expired"] .quote-cta-message-expired,
[data-order-status="closed"] .quote-cta-message-closed {
	display: block;
}
