.btn {
	font-family: $font-button1;
	font-size: 0.875em;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	border-radius: 0.375em;
	padding: 0.75em 1.75em;
	margin: 0.2em 0.1em;
	text-decoration: none !important;
	-webkit-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	-o-transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	transition: color 1.0s ease-out, background 1.0s ease-out, border 1.0s ease-out;
	&:hover:not(:disabled):not(.disabled) {
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
	}
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0  !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		// -webkit-box-shadow: 0 0 8px rgba($gray-white1, 0.8)!important;
		// -moz-box-shadow: 0 0 8px rgba($gray-white1, 0.8) !important;
		// box-shadow: 0 0 8px rgba($gray-white1, 0.8) !important;
	}
}

.btn-sm {
	font-size: 0.875rem;
	padding: 0.375em 0.9375em;
	text-transform: none;
	font-weight: 400;
	letter-spacing: 0;
}

.btn-lg {
	font-size: 1.125em;
	padding: 0.8125em 1.625em;
}

.btn-primary {
	color: $white;
	background-color: $brandcolor1;
	border: none;
	border-bottom: 2px solid $brandcolor1-dark;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor1-light;
		border: none;
		border-bottom: 2px solid $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border: none;
		border-bottom: 2px solid $brandcolor1-dark;
	}
	&:disabled {
		color: $gray-white2;
		background-color: $gray-light2;
		border: none;
	}
}

.btn-secondary {
	color: $white;
	background-color: $brandcolor2;
	border: none;
	border-bottom: 2px solid $brandcolor2-dark;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor2-light;
		border: none;
		border-bottom: 2px solid $brandcolor2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor2-dark;
		border: none;
		border-bottom: 2px solid $brandcolor2-dark;
	}
	&:disabled {
		color: $gray-white3;
		background-color: $gray-light2;
		border: none;
	}
}

.btn-danger {
	color: $white;
	background-color: $brandcolor3;
	border: none;
	border-bottom: 2px solid $brandcolor3-dark;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor3-light;
		border: none;
		border-bottom: 2px solid $brandcolor3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor3-dark;
		border: none;
		border-bottom: 2px solid $brandcolor3-dark;
	}
	&:disabled {
		color: $gray-white3;
		background-color: $gray-light2;
		border: none;
	}
}

.btn-gray {
	color: $white;
	background-color: $gray-mid2;
	border: none;
	border-bottom: 2px solid $gray-dark1;
	&:hover:not(:disabled):not(.disabled) {
		color: $brandcolor1-dark;
		background-color: $gray-light3;
		border: none;
		border-bottom: 2px solid $gray-mid2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-dark1;
		border: none;
		border-bottom: 2px solid $gray-dark1;
	}
	&:disabled {
		color: $gray-white1;
		background-color: $gray-light1;
		border: none;
	}
}

.btn-outline-primary {
	background-color: none;
	color: $brandcolor1;
	border: 1px solid $brandcolor1;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor1;
		border: 1px solid $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border: 1px solid $brandcolor1-dark;
	}
	&:disabled {
		color: $gray-light3;
		background-color: none;
		border: 1px solid $gray-light3;
	}
}

.btn-outline-secondary {
	color: $brandcolor2;
	border: 1px solid $brandcolor2;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor2;
		border: 1px solid $brandcolor2;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor2-dark;
		border: 1px solid $brandcolor2-dark;
	}
	&:disabled {
		color: $gray-light2;
		background-color: none;
		border: 1px solid $gray-light2;
	}
}

.btn-outline-gray {
	color: $gray-dark1;
	border: 1px solid $gray-dark1;
	&:hover:not(:disabled):not(.disabled) {
		color: $brandcolor1;
		background-color: $gray-light3;
		border: 1px solid $gray-light3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $gray-dark1;
		border: 1px solid $gray-dark1;
	}
	&:disabled {
		color: $gray-light1;
		background-color: none;
		border: 1px solid $gray-light1;
	}
}

.btn-dark {
	background-color: $black;
	border: none;
	border-bottom: 2px solid $gray-dark3;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor1;
		border: none;
		border-bottom: 2px solid $brandcolor1-dark;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: none;
		border-bottom: 2px solid $brandcolor1-dark;
	}
	&:disabled {
		color: $gray-light3;
		background-color: $gray-mid3;
		border: none;
	}
}

.btn-light {
	color: $brandcolor1;
	background-color: $white;
	border: none;
	border-bottom: 2px solid $gray-white2;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor1;
		border: none;
		border-bottom: 2px solid $brandcolor1-dark;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1;
		border: none;
		border-bottom: 2px solid $brandcolor1-dark;
	}
	&:disabled {
		color: $gray-light2;
		background-color: $gray-white2;
		border: none;
	}
}

.btn-outline-dark {
	background-color: none;
	color: $black;
	border: 1px solid $black;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor1;
		border: 1px solid $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border: 1px solid $brandcolor1-dark;
	}
	&:disabled {
		color: $gray-mid3;
		background-color: none;
		border: 1px solid $gray-mid3;
	}
}

.btn-outline-light {
	background-color: none;
	color: $white;
	border: 1px solid $white;
	&:hover:not(:disabled):not(.disabled) {
		color: $white;
		background-color: $brandcolor1-light;
		border: 1px solid $white;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $white;
		background-color: $brandcolor1-dark;
		border: 1px solid $white;
	}
	&:disabled {
		color: $gray-white2;
		background-color: none;
		border: 1px solid $gray-white2;
	}
}

.btn-link {
	padding: 0;
	color: $brandcolor1;
	text-decoration: none;
	border: none;
	&:hover:not(:disabled):not(.disabled) {
		color: $brandcolor1-light;
		text-decoration: underline;
		border: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: underline;
		border: none;
	}
	&:disabled {
		color: $gray-light2;
		text-decoration: underline;
		border: none;
	}
}
