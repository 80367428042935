.header-agent-nav-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	margin: 0;
	padding: 0 1.875rem;
	background-color: $brandcolor1;
	@media (max-width: 575.9px) {
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
		background-color: $gray-light1;
		.navbar {
			width: 100%;
			background-color: $brandcolor1;
		}
	}
	.agent-new-buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		grid-gap: 0.3125rem;
		.btn-sm {
			padding: 0.75em 1.75em;
		}
		@media (max-width: 575.9px) {
			justify-content: center;
			padding: 0.3125rem 1.875rem;
			width: 100%;
			// order: -1;
			.btn-outline-light {
				width: 100%;
				color: $brandcolor1;
				border: 1px solid $brandcolor1;
				&:hover:not(:disabled):not(.disabled) {
					color: $white;
					background-color: $brandcolor1;
					border: 1px solid $brandcolor1;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
					background-color: $brandcolor1-dark;
					border: 1px solid $brandcolor1-dark;
				}
				&:disabled {
					color: $gray-light3;
					background-color: none;
					border: 1px solid $gray-light3;
				}
			}
		}
	}
}

#agent-quote-menu {
	margin: 0;
	padding: 0;
	.nav-item {
		margin: 0;
		padding: 0;
		.nav-link {
			color: $white;
			margin: 0;
			padding: 1.0em 1.25em 1.0em 1.25em !important;
			border-radius: 0.375em 0.375em 0 0;
			background-color: none;
			&:hover {
				color: $white;
				background-color: $brandcolor2-light;
				text-decoration: none;
				border-bottom-color: $brandcolor2-light;
				box-shadow:
					0px -0.3px 0.3px rgba(0, 0, 0, 0.031),
					0px -0.9px 0.9px rgba(0, 0, 0, 0.044),
					0px -1.8px 1.8px rgba(0, 0, 0, 0.056),
					0px -3.7px 3.7px rgba(0, 0, 0, 0.069),
					0px -10px 10px rgba(0, 0, 0, 0.1)
				;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $white;
				background-color: $brandcolor2-dark;
				border-bottom-color: $brandcolor2-dark;
				text-decoration: none;
			}
			&.active {
				color: $brandcolor2;
				background-color: $gray-white0;
				box-shadow:
					0px -0.3px 0.3px rgba(0, 0, 0, 0.031),
					0px -0.9px 0.9px rgba(0, 0, 0, 0.044),
					0px -1.8px 1.8px rgba(0, 0, 0, 0.056),
					0px -3.7px 3.7px rgba(0, 0, 0, 0.069),
					0px -10px 10px rgba(0, 0, 0, 0.1)
				;
				&:hover {
					border-bottom-color: $gray-white0;
				}
			}
		}
	}
}

@media (max-width: 575.9px) {
	#agent-quote-menu {
		margin: 0;
		padding: 0;
		.nav-item {
			margin: 0;
			.nav-link {
				color: $white;
				margin: 0;
				padding: 1.0em 1.25em !important;
				border-radius: 0;
				background-color: none;
				&:hover {
					color: $white;
					background-color: $brandcolor2-light;
					text-decoration: none;
					border-bottom-color: $brandcolor2-light;
					box-shadow: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $white;
					background-color: $brandcolor2-dark;
					border-bottom-color: $brandcolor2-dark;
					text-decoration: none;
				}
				&.active {
					color: $white;
					background-color: $brandcolor2-dark;
					box-shadow: none;
					&:hover {
						border-bottom-color: $brandcolor2-dark;
					}
				}
			}
		}
	}
}

