/* Product Listing Pages */
.product-type-list-container {
	margin: 0;
	padding: 0 0 0 1.875em;
	@media (max-width: 767.9px) {
		padding: 0;
		margin-top: 1.25rem;
	}
	#accordion {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: wrap;
		gap: 0;
		@media (max-width: 767.9px) {
			padding: 0;
		}
	}
	.card {
		margin: 0;
		padding: 0;
		width: calc(100% - 1.25em);
		@media (max-width: 767.9px) {
			width: 100%;
		}
		.card-header {
			margin: 0;
			padding: 1.25rem 0 0.3125rem 0;
			border-bottom: 1px solid $gray-mid2;
			.btn {
				margin: 0;
				padding: 0;
				text-transform: none;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-grow: 1;
				flex-wrap: wrap;
				.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
					outline: 0 !important;
					outline-offset: 0 !important;
					background-image: none !important;
					-webkit-box-shadow: none !important;
					-moz-box-shadow: none !important;
					box-shadow: none !important;
				}
				&[aria-expanded="true"] {
					.card-title {
						.fac { transform: rotate(180deg); }
					}
				}
				.card-title {
					width: auto;
					.fac {
						display: inline-block;
						margin-right: 0.3125em;
						transition: transform 300ms ease-in-out;
						transform: rotate(0deg);
						color: $brandcolor1;
					}
					h3 {
						display: inline-block;
						color: $brandcolor1;
						margin: 0;
					}
					@media (max-width: 767.9px) {
						width: 100%;
					}
				}
				.card-desc {
					display: inline-block;
					width: 50%;
					p {
						display: inline;
						position: relative;
						color: $gray-mid2;
						line-height: 1;
						font-weight: 400;
						letter-spacing: 0;
					}
					@media (max-width: 767.9px) {
						width: 100%;
						display: none;
					}
				}
			}
		}
		.card-body {
			padding: 0;
		}
	}
}

.btn-nav-accordion-button {
	margin: 0;
	padding: 0.625rem;
	border-radius: 0;
	border-bottom: 1px solid $brandcolor1-light;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&[aria-expanded="true"] {
		.card-title {
			.fac { transform: rotate(180deg); }
		}
	}
	@media (max-width: 575.9px) {
		// margin-bottom: 1.25rem;
	}
	.fac {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $brandcolor1;
	}
	.category-nav-text {
		display: inline-block;
		margin: 0;
		color: $brandcolor1;
		line-height: 1;
	}
}

.product-nav-sticky {
}

#product-category-nav {
	@media (min-width: 767.9px) {
		// display: block;
	}
	.product-category-nav-list {
		@media (max-width: 767.9px) {
			margin-bottom: 1.25rem !important;
			li {
				text-align: center;
				a {
					font-size: 1.25rem;
				}
			}
		}
	}
}

.product-type-nav {
	border-top: 2px solid $brandcolor2;
	li {
		margin: 0;
		padding: 0.5rem 0 !important;
		a {
			margin: 0 !important;
			padding: 0 !important;
			font-size: 1.0rem;
			font-weight: 400;
			border: none;
			border-width: 0;
			box-shadow: none;
			appearance: none;
			-webkit-appearance: none;
		}
	}
}

/* Product Teaser Cards */
.product-teaser-card {
	padding: 1.25em 0;
	width: 100%;
	// width: calc(33.33% - 0.9375em);
	// @media (max-width: 991.9px) {
	// 	width: calc(50% - 1.25em);
	// }
	// @media (max-width: 575.9px) {
	// 	width: 100%;
	// }
	&:hover {
		.card-title {
			color: $brandcolor1-light;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-title {
			color: $brandcolor1-dark;
		}
	}
	.card-header {
		padding-bottom: 0.5em;
		.row {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
		}
		.card-title {
			margin-bottom: 0.125em;
			color: $brandcolor1;
			.fa, .fac {
				font-size: 0.5em;
				transform: translateY(-0.1875em);
			}
		}
		.product-teaser-category {
			color: $brandcolor2;
			&:hover {
				.card-title {
					color: $brandcolor1-light;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				.card-title {
					color: $brandcolor1-dark;
				}
			}
		}
	}
	.card-img {
		width: 20%;
		padding-right: 0.625em;
	}
	.card-body {
		margin-top: 0;
		padding-top: 0;
		display: grid;
		grid-template-columns: 1fr minmax(min-content, max-content);
		grid-gap: 0.25em;
		border-top: 1px solid $gray-light2;
		.product-teaser-desc {
			padding-top: 0.5em;
			p {
				color: $gray-mid2;
				font-size: 0.875rem;
				line-height: 1.25;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 6;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
			}
		}
		.product-teaser-docs {
			padding-top: 0.5em;
			padding-left: 0.25em;
			border-left: 1px solid $gray-mid2;
			.product-teaser-doc-link {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-bottom: 0.5em;
				width: 100%;
				font-weight: 700;
				.fa, .fac {
					font-size: 2.0em;
				}
			}
		}
		.product-teaser-docs-no-desc {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 0.5em;
			border-left: none;
			.product-teaser-doc-link {
				width: auto;
			}			
		}
	}
}

.product-type-list-products-add-container {
	padding: 0.625em 0;
	font-size: 0.9375em;
}

.product-type-list-products-add {
	display: grid;
	grid-template-columns: minmax(min-content, auto) repeat(2, minmax(min-content, max-content)) minmax(min-content, auto) repeat(3, minmax(min-content, max-content));
	grid-gap: 0.75em 0.5em;
	justify-items: start;
	align-items: center;
	.table-col, .table-col-header {
		margin: 0;
		padding: 0;
		// overflow: hidden;
		// white-space: nowrap;
		text-overflow: ellipsis;
	}
	.table-row-divider {
		grid-column: span 7;
		border-bottom: 1px solid $gray-light2;
		&:last-child {
			border-bottom: 1px solid $gray-mid2;
		}
	}
	input {
		padding: 0.125em 0.25em !important;
		height: auto;
	}
	select {
		padding: 0.125em 1.0em 0.125em 0.25em !important;
		height: auto;
	}
	.table-col-add-button {
		justify-self: flex-end;
		padding-right: 0.125em;
	}
	@media (max-width: 575.9px) {
		grid-template-columns: repeat(4, minmax(min-content, 1fr)) repeat(2, minmax(min-content, max-content)); 
		.table-col-header {
			display: none;
		}
		.table-col-product-title {
			grid-column: 1 / span 4;
		}
		.table-col-manufacturer {
			grid-column: 5 / span 3;
			justify-self: flex-end;
			// padding-right: 0.125em;
		}
		.table-col-msds-link, .table-col-label-link {
			display: none;
		}
		.table-col-qty-needed {
			grid-column: 1 / span 2;
			justify-self: stretch;
		}
		.table-col-pack-size {
			grid-column: 3 / span 4;
			justify-self: stretch;
			select {
				width: 100% !important;
			}
		}
	}
}


