/* Common Agent Backend Classes */



/*Agent Quote Navigation */
.agent-quote-navigation-bar {
	margin: 0;
	padding: 0;
	background-color: $brandcolor2;
	@media (max-width: 991.9px) {
		background-color: $gray-light1;
	}
	.agent-quote-nav-container {
		margin: 0;
		padding: 0;
		@media (max-width: 991.9px) {
			flex-direction: column;
		}
		.navbar {
			padding: 0.625rem 1.875rem 0 1.875rem;
			background-color: $brandcolor2 !important;
			@media (max-width: 991.9px) {
				width: 100%;
				justify-self: stretch;
			}
			@media (max-width: 575.9px) {
				padding: 0;
			}
		}
		.agent-quote-edit-buttons {
			padding: 0 1.875rem;
			form {
				.form-group {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					grid-gap: 0.3125rem;
				}
			}
			.btn {
				margin: 0 0.3125em;
				padding: 0.75em 1.75em;
			}
			select {
				margin: 0 0.3125em;
				padding: 0.5625em 0.625em 0.5625em 0.625em;
				min-width: 5em;
			}
			label {
				color: $white;
			}
			.btn-save {
				margin-right: 0;
			}
			@media (max-width: 991.9px) {
				padding: 0.625rem 1.875rem;
				width: 100%;
				justify-self: stretch;
				.btn-outline-light {
					color: $brandcolor1;
					border: 1px solid $brandcolor1;
					&:hover:not(:disabled):not(.disabled) {
						color: $white;
						background-color: $brandcolor1;
						border: 1px solid $brandcolor1;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $white;
						background-color: $brandcolor1-dark;
						border: 1px solid $brandcolor1-dark;
					}
					&:disabled {
						color: $gray-light3;
						background-color: none;
						border: 1px solid $gray-light3;
					}
				}
			}
			@media (max-width: 575.9px) {
				display: none;
				form {
					.form-group {
						flex-direction: column;
						justify-content: center;
						align-items: center;
						.btn {
							width: 100%;
							justify-self: stretch;
						}
					}
				}
			}
		}
	}
}

/*Agent Quote Action Buttons */
.agent-quote-edit-buttons-outer-container {
	padding: 0;
	// background-color: $white;
	.agent-quote-edit-buttons-row {
		padding: 1.25rem 1.875rem;
		.agent-quote-edit-buttons-container {
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 0.625rem;
			@media (max-width: 575.9px) {
				flex-direction: column;
				justify-content: stretch;
				align-items: stretch;
			}
			.agent-quote-edit-buttons {
				.form-group  {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					gap: 0.625rem;
					.btn {
						margin: 0;
						padding: 0.75em 2.5em;
					}
					select {
						margin: 0 0.3125em;
						padding: 0.3125em 0.625em 0.3125em 0.625em;
						min-width: 5em;
					}
					label {
						color: $gray-dark2;
						font-size: 1.125rem;
					}
					@media (max-width: 575.9px) {
						flex-direction: column;
						justify-content: stretch;
						align-items: stretch;
						.btn {
							width: 100%;
							font-size: 1.125em;
							padding: 0.8125em 1.625em;
						}
					}
				}
			}
		}
	}
}

/* Agent Quote Search Page */
.quote-search-layout-container {
	display: grid;
	grid-template-columns: 1fr minmax(17.5em, max-content);
	margin: 0;
	padding: 0;
	@media (max-width: 767.9px) {
		grid-template-columns: 100%;
	}
	.main-content-column {
		padding-bottom: 7.5em;
	}
	.search-filter-options-column {
		background-color: $gray-white2;
		@media (max-width: 767.9px) {
			order: -1;
		}
	}
}

/* Quote Filters */
.quote-filters-container {
	background-color: $brandcolor2;
	padding: 0 1.25rem;
	width: 100%;
	@media (max-width: 767.9px) {
		padding: 1.25rem 1.875rem;
	}
	.quote-filters-title-row {
		@media (max-width: 767.9px) {
			display: none;
		}
		.quote-filters-title {
			color: $white;
			font-size: 1.125em;
			// font-style: normal;
			// font-weight: 400;
			margin-bottom: 0.625em;
		}
	}
	label {
		color: $white;
		margin-bottom: 0.3125em;
	}
	.row {
		padding: 0.3125em 0;
	}
	.btn-filter-results-row {
		padding: 1.875em 0 0 0;
	}
	.btn-filter-clear-row {
		padding: 0.625em 0 0 0;
		.btn-filter-clear {
			padding: 0 0.125em;
			color: $white;
			font-weight: 400;
			text-transform: none;
			letter-spacing: 0;
			border-radius: 0;
			border-bottom: 1px solid $white;
		}
	}
}
.btn-quote-filters-accordion-button {
	margin: 0;
	padding: 1.25rem 1.875rem;
	color: $white;
	background-color: $brandcolor2;
	border-radius: 0;
	border: none;
	// border-bottom: 1px solid $brandcolor1-light;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
		outline: 0 !important;
		outline-offset: 0 !important;
		background-image: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	&[aria-expanded="true"] {
		.card-title {
			.fac { transform: rotate(180deg); }
		}
	}
	@media (max-width: 575.9px) {
		// margin-bottom: 1.25rem;
	}
	.fac {
		display: inline-block;
		margin-right: 0.3125em;
		line-height: 1;
		transition: transform 300ms ease-in-out;
		transform: rotate(0deg);
		color: $white;
	}
	.quote-filters-title {
		display: inline-block;
		margin: 0;
		color: $white;
		font-size: 1.125em;
		line-height: 1;
		// margin-bottom: 0.625em;
	}
}

/* Quote Filtered List */
.quote-filters-chips-container {
	padding: 0 1.875em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.quote-filters-list-container {
	padding: 1.25em 1.875em 2.5em 1.875em;
	font-size: 0.9375em;
	.quote-filters-table {
		display: grid;
		grid-template-columns: minmax(min-content, 0.33fr) 1.5fr 1fr minmax(min-content, 0.33fr) minmax(min-content, max-content);
		grid-gap: 0.5em 0.5em;
		// justify-items: start;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			a {
				font-weight: 400;
				color: $black;
				text-decoration: none;
				&:hover {
					color: $brandcolor1-light;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
		.table-header {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
		}
		.table-header-divider {
			grid-column: span 5;
			border-bottom: 1px solid $gray-mid2;
			margin: 0.125em 0 0.3125em 0;
			height: 1px;
		}
		.table-row-divider {
			grid-column: span 5;
			padding: 0;
			border-bottom: 1px solid $gray-light2;
			margin: 0.125em 0;
			height: 1px;
			&:first-child {
				// border-bottom: 1px solid $gray-mid2;
				// margin: 0;
			}
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
		.table-col-link {
			height: 100%;
			a {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				position: relative;
				color: $brandcolor1;
				height: 100%;
			}
		}
		@media (max-width: 575.9px) {
			grid-template-columns: repeat(4, minmax(min-content, 1fr)) minmax(min-content, max-content);
			.table-col-header {
				display: none;
			}
			.table-col-product-status {
				grid-column: 1 / span 2;
			}
			.table-col-buyer {
				grid-column: 3 / span 3;
				justify-self: flex-end;
				justify-content: flex-end;
			}
			.table-col-agent:not(.table-col-header) {
				grid-column: 1 / span 2;
			}
			.table-col-date:not(.table-col-header) {
				grid-column: 3 / span 2;
				justify-self: flex-end;
				justify-content: flex-end;
			}
		}
	}
}

/* Quoted Products Layout */
.quote-products-layout-container {
	display: grid;
	grid-template-columns: 1fr minmax(17.5em, .20fr);
	margin: 0;
	padding: 0;
	@media (max-width: 991.9px) {
		grid-template-columns: 100%;
	}
	.main-content-column {
		// padding-bottom: 2.5em;
	}
	.quote-info-column {
		background-color: $gray-white2;
		@media (max-width: 991.9px) {
			order: 2;
		}
	}
}

/* Quote Info List */
.quote-info-list-container {
	background-color: $gray-light2;
	padding: 1.875rem 1.25rem;
	width: 100%;
	.quote-info-list-table {
		display: grid;
		grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr);
		grid-gap: 0.3125em 0.0625em;
		font-size: 0.875em;
		padding: 1.25em 0;
		.table-col, .table-col-label, .table-col-long {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col-label {
			justify-self: start;
			text-align: left;
			font-weight: 700;
		}
		.table-col {
			justify-self: end;
			text-align: right;
		}
		.table-col-left {
			justify-self: start;
			text-align: left;
		}
		.table-col-long {
			@media (min-width: 767.9px) {
				grid-column: span 2;
				justify-self: start;
				text-align: left;
			}
		}
		.table-row-divider {
			grid-column: span 2;
			padding: 0;
			border-bottom: 1px solid $white;
			margin: 0.3125em 0;
			height: 1px;
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
	}
	.quote-info-list-buttons-container {
		.btn-duplicate-quote {
			color: $brandcolor1;
			&:hover {
				color: $white;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $white;
			}
		}
	}
}

/* Quote Products Edit List */
.quote-products-overview-container {
	padding: 2.5rem 1.875rem;
	font-size: 1.125em;
	@media (max-width: 767.9px) {
		padding: 1.25rem 1.875rem 0.625rem 1.875rem;
	}
	.quote-products-overview-table {
		display: grid;
		grid-template-columns: minmax(min-content, max-content) minmax(min-content, 1fr);
		grid-gap: 0.3125em 1.25em;
		justify-content: flex-start;
		align-items: center;
		@media (max-width: 767.9px) {
			grid-gap: 0.3125em 0.625em;
		}
		.table-col, .table-col-label {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col-label {
			margin-bottom: 0;
			color: $brandcolor2;
			font-weight: 700;
		}
		.table-col {
		}
		.table-row-divider {
			grid-column: span 2;
			padding: 0;
			border-bottom: 1px solid $gray-light2;
			margin: 0.3125em 0;
			height: 1px;
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
		.table-col-label-freight-progress {
			align-self: start;
		}
		.table-col-freight-progress {
			display: grid;
			grid-template-columns: minmax(min-content, max-content) 1fr;
			grid-gap: 0.3125em;
			align-self: start;
			.progress-bar-label {
				grid-column: span 2;
				color: $gray-mid2;
				font-size: 0.875em;
			}
		}
	}
}
.quote-products-list-edit-title-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.625em 1.875em;
	.quote-products-list-edit-title-container {
		color: $brandcolor2;
		font-size: 1.125em;
		font-weight: 700;
	}
	.quote-products-list-edit-discount-form {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		column-gap: 0.3125em;
		.input-quote-product-list-discount-percent {
			border-radius: 0.375em 0 0 0.375em !important;
		}
		.btn-apply-discount {
			padding: 0.5em 2.5em;
		}
	}
}
.quote-products-list-edit-table-container {
	padding: 0.625em 1.875em 5.0em 1.875em;
	.quote-products-list-edit-table {
		margin-bottom: 0;
		display: grid;
		grid-template-columns: minmax(min-content, max-content) minmax(min-content, 3fr) repeat(4, 1fr) repeat(2, minmax(min-content, max-content));
		// grid-gap: 0.75em 0.5em;
		justify-items: start;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			padding: 0;
			// overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-col {
			padding: 0.75em 0;
		}
		.table-col-header {
			padding-bottom: 0.375em;
		}
		.table-row-divider {
			grid-column: 2 / span 7;
			margin: 0;
			padding: 0;
			border-bottom: 1px solid $gray-light2;
			&:first-child {
				border-top: 1px solid $gray-mid2;
			}
			// &:last-child {
			// 	border-bottom: 1px solid $gray-mid2;
			// }
		}
		.table-row-divider-header {
			border-bottom: 1px solid $black;
		}
		.table-row-divider-addons {
			border-bottom: 1px solid $brandcolor2-white3;
		}
		.table-row-divider-addons-footer {
			border-bottom: 1px solid $brandcolor2;
		}
		.table-row-divider-footer {
			border-bottom: 1px solid $gray-mid2;
		}
		.table-col-product-title {
			justify-self: stretch;
			justify-content: flex-start;
			padding-right: 0.75rem;
		}
		.table-col-delete-button, .table-col-add-button {
			a, button {
				color: $gray-mid2;
				&:hover {
					color: $brandcolor1-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
				}
			}
		}
		.table-col-product-title-removed {
			text-decoration: line-through;
			a {
				color: $gray-mid2;
				font-weight: 400;
				&:hover {
					color: $brandcolor1-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
				}
			}
		}
		.table-col-removed {
			color: $gray-mid2;
		}
		.table-col-product-notes {
			grid-column: 2 / span 7;
			justify-self: stretch;
			background-color: $gray-white1;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 1.25em;
			padding: 0.375em 0.75em;
			font-size: 0.875em;
			line-height: 1;
			.product-notes-chip {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 0.3125rem;
				line-height: 1;
				b {
					color: $brandcolor2;
					font-family: $font-body1-mobile;
				}
				label {
					margin: 0;
					padding: 0;
					color: $brandcolor2;
					font-family: $font-body1-mobile;
					font-size: 0.875rem;
					font-weight: 700;
					line-height: 1;
				}
			}
		}
		.table-col-add-product {
			grid-column: 2 / span 7;
			margin: 0;
			justify-self: stretch;
			padding: 0.625em;
			background-color: $gray-white2;
			// border-bottom: 1px solid $gray-mid2;
			.quote-detail-add-product-button {
				// background-color: $white;
				padding: 0.625em 2em 1em 2em;
				.fa, .fac {
					font-size: 1.625em;
					transform: translateY(0.125em);
				}
				@media (max-width: 575.9px) {
					width: 100%;
				}
			}
		}
		.table-col-header-addons {
			grid-column: 2 / span 7;
			justify-self: stretch;
			position: relative;
			padding: 0.3125em;
			color: $brandcolor2;
			background-color: $brandcolor2-white2;
			font-size: 0.875em;
			font-weight: 700;
			&:before {
				display: inline-block;
				position: absolute;
				top: -0.625em;
				left: 0.3125em;
				content: '\f032';
				font-family: 'ReichmanSales';
				font-size: 2.0em;
				font-weight: normal;
				font-style: normal;
				line-height: 1;
				text-rendering: auto;
				color: $brandcolor2-white2;
			}
		}
		.table-col-addons {
			padding: 0.5em 0;
			justify-self: stretch;
			align-self: stretch;
			display: flex;
			justify-content: stretch;
			align-items: center;
			background-color: $brandcolor2-white1;
			.btn-addon {
				text-align: left;
				display: block;
				margin: 0;
				padding: 0;
				width: 100%;
				font-size: 1.0em;
				font-weight: 400;
				text-transform: initial;
				letter-spacing: 0;
				line-height: 0;
				.fa, .fac {

				}
			}
			&.table-col-product-title {
				padding-left: 0.5em;
			}
		}
		.table-total-col {
			padding: 1.25em 0;
			justify-self: stretch;
			align-self: stretch;
			background-color: $brandcolor1-white2;
			border-top: 2px solid $black;
			border-bottom: 1px solid $black;
		}
		.table-col-total-label {
			grid-column: 2 / span 4;
			padding: 1.25em 0.625em;
			color: $brandcolor2;
			font-size: 1.125em;
			font-weight: 700;
		}
		select {
			font: inherit;
			display: inline-block;
			background: transparent;
			outline: none !important;
			border: none;
			border-radius: 0;
			border-spacing: 0;
			padding: 1px 2px;
			line-height: 1.4em;
			padding: 0;
			margin: 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-appearance: none;
			-moz-appearance: none;
			box-shadow: none;
			-webkit-box-shadow: none;
			cursor: pointer;
		}
		input[type="text"],
		input[type="number"] {
			font: inherit;
			display: inline-block;
			background: transparent;
			outline: none !important;
			border: transparent;
			border-radius: 0;
			border-spacing: 0;
			padding: 1px 2px;
			box-shadow: none;
			-webkit-box-shadow: none;
			transition: all 100ms linear;
			overflow: visible;
			line-height: 1.4em;
			cursor: pointer;
			&.width-auto {
				text-align: right;
				width: calc(3ch + 4px);
			}
			&.empty:not(.input-substituted-reason),
			&:focus {
				cursor: auto;
				background: $white;
				border: 1px solid $gray-light2;
				border-radius: 0.375em !important;				
				-webkit-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
				-moz-box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
				box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.25em rgba($brandcolor1-light, 0.6);
			}
			&[data-type="currency"].width-auto {
				width: calc(6ch + 6px);
			}

		}
		.table-col-qty-needed {
			// text-align: right;
		}
		.table-col-quote-price {
			// text-align: right;
			input {
				font-weight: bold;
			}
		}
		@media (max-width: 666.9px) {
			grid-template-columns: repeat(8, fit-content);
			.table-col {
				padding: 0.125em 0;
			}
			.table-col-header {
				display: none;
			}
			.table-col-checkbox	{
				// display: none;
			}
			.table-col-product-title {
				grid-column: 2 / span 6;
			}
			.table-col-label-link {
				grid-column: 2 / span 2;
			}
			.table-col-qty-needed {
				grid-column: 4 / span 2;
			}
			.table-col-pack-size {
				grid-column: 6 / span 2;
				justify-self: flex-end;
				justify-content: flex-end;
			}
			.table-col-suggested-price {
				grid-column: 2 / span 3;
			}
			.table-col-quote-price {
				grid-column: 5 / span 3;
				justify-self: flex-end;
				justify-content: flex-end;
			}
			.table-col-delete-button, .table-col-add-button {
				grid-column: 8 / span 1;
				justify-self: flex-end;
				justify-content: flex-end;
			}
			.table-col-product-notes {
				padding: 0.125em 0.75em;
			}
			.table-col-add-product {
				padding: 0.625em;
			}
			.table-total-col {
				padding: 1.25em 0;
			}
			.table-col-total-label {
				grid-column: 2 / span 2;
				padding: 1.25em 0.625em;
			}
			.table-col-total-suggested-price {
				grid-column: 4 / span 1;
				justify-self: stretch;
				justify-content: flex-end;
			}
			.table-col-total-quote-price {
				grid-column: 5 / span 3;
				justify-self: stretch;
				justify-content: flex-end;
				text-align: right;
			}
			.table-col-header-addons {
				grid-column: 2 / span 7;
				padding: 0.4375em 0.3125em;
				&:before {
					top: -0.5625em;
				}
			}
			.table-col-addons.table-col-product-title {
				grid-column: 2 / span 6;
			}
			.table-col-addons.table-col-label-link {
				grid-column: 8 / span 1;
				justify-self: stretch;
				justify-content: stretch;
			}
			.table-col-addons.table-col-qty-needed,
			.table-col-addons.table-col-pack-size,
			.table-col-addons.table-col-suggested-price,
			.table-col-addons.table-col-quote-price,
			.table-col-addons.table-col-delete-button {
				display: none;
			}
			.table-row-divider {
				grid-column: 2 / span 7;
				padding: 0;
			}
		}
	}
}

/* Quoted Messages Layout */
.quote-messages-layout-container {
	display: grid;
	grid-template-columns: 1fr minmax(17.5em, .20fr);
	margin: 0;
	padding: 0;
	@media (max-width: 767.9px) {
		grid-template-columns: 100%;
	}
	.main-content-column {
	}
	.quote-info-column {
		background-color: $gray-white2;
		@media (max-width: 767.9px) {
			order: 2;
		}
	}
}

/* Quote Message History */
.quote-message-history-title-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.875rem 1.875em 0 1.875rem;
	.quote-message-history-title-container {
		padding: 0.625em 0;
		color: $brandcolor2;
		font-size: 1.125em;
		font-weight: 700;
		border-bottom: 1px solid $gray-light2;
	}
}
.quote-message-history-container {
	padding: 0.625em 1.875em 3.75rem 1.875rem;
	.quote-message-container {
		padding: 0.625em 0;
		.quote-message-title-container {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-bottom: 0.625em;
			//gap: 0.625em;
			.quote-message-title-name {
				color: $black;
			}
			.quote-message-title-date {
				color: $gray-mid2;
				margin-left: 0.625em;
				padding: 0.3125em 0.625em;
				border-left: 2px solid $brandcolor2;
			}
		}
		.quote-message-body-row {
			margin: 0;
			.quote-message-body-container {
				position: relative;
				border-radius: 0.375em;
				padding: 1.25em;
				box-shadow:
					0px 0.5px 1.1px rgba(0, 0, 0, 0.035),
					0px 1.5px 3px rgba(0, 0, 0, 0.05),
					0px 3.6px 7.2px rgba(0, 0, 0, 0.065),
					0px 12px 24px rgba(0, 0, 0, 0.1);
				p {
					font-size: 1.125em;
					line-height: 1.125;
					margin-bottom: 0;
				}
				&:before {
					display: inline;
					position: absolute;
					top: -0.59375em;
					z-index: 2;
					content: '\f032';
					font-family: 'ReichmanSales';
					font-size: 2.0em;
					font-weight: normal;
					font-style: normal;
					line-height: 1;
					text-rendering: auto;
				}
			}
		}
	}
	.quote-message-customer-container {
		margin-right: 3.75em;
		.quote-message-title-container {
			justify-content: flex-start;
		}
		.quote-message-body-container {
			background-color: $white;
			border: 1px solid $gray-light2;
			border-radius: 0 0.375em 0.375em 0.375em;
			p {
				color: $gray-dark2;
			}
			&:before {
				left: 0.625em;
				color: $white;
				text-shadow: 0 -1px 1px $gray-light2;
			}
		}
	}
	.quote-message-agent-container {
		margin-left: 3.75em;
		.quote-message-title-container {
			justify-content: flex-end;
		}
		.quote-message-body-container {
			background-color: $brandcolor1-white1;
			border: 1px solid $brandcolor1-light2;
			border-radius: 0.375em 0.375em 0 0.375em;
			p {
				color: $gray-mid2;
			}
			&:before {
				right: 0.625em;
				color: $brandcolor1-white1;
				text-shadow: 0 -1px 1px $brandcolor1-light2;
			}
		}
	}
}

.quote-message-send-container-outer {
	margin: 3.75rem 0 0 0;
	background-color: $brandcolor1-white2;
	.quote-message-send-editor-container {
		margin: 0;
		padding: 1.875em 1.25em 0 1.25em;
		textarea, .redactor-box {
			margin: 0 !important;
		}
	}
	.quote-message-send-button-container {
		padding: 0.625em 1.25em 1.875rem 1.25rem;
		@media (max-width: 575.9px) {
			.btn {
				width: 100%;
			}
		}
	}
	.quote-message-send-agent-row {
		// padding-left: 3.75em;
	}
	.quote-message-send-customer-row {
		// padding-right: 3.75em;
	}
}

/* Quote Notes History */
.quote-note-history-container {
	padding: 2.875em 1.875em;
	.quote-note-container {
		padding: 0.625em 0;
		.quote-note-title-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 0.625em;
			.quote-note-title {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 0.625em;
				.quote-note-title-name {
					color: $black;
				}
				.quote-note-title-date {
					color: $gray-mid2;
					// margin-left: 0.625em;
					padding: 0.3125em 0.625em;
					border-left: 2px solid $brandcolor2;
				}
			}
			.quote-note-buttons {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 0.625em;
			}
		}
		.quote-note-body-row {
			margin: 0;
			.quote-note-body-container {
				position: relative;
				border-radius: 0.375em;
				padding: 1.25em;
				background-color: $white;
				border: 1px solid $gray-light2;
				border-radius: 0 0.375em 0.375em 0.375em;
				box-shadow:
					0px 0.5px 1.1px rgba(0, 0, 0, 0.035),
					0px 1.5px 3px rgba(0, 0, 0, 0.05),
					0px 3.6px 7.2px rgba(0, 0, 0, 0.065),
					0px 12px 24px rgba(0, 0, 0, 0.1);
				p {
					color: $gray-dark2;
					font-size: 1.125em;
					line-height: 1.125;
					margin-bottom: 0;
				}
				&:before {
					display: inline;
					position: absolute;
					top: -0.59375em;
					z-index: 2;
					left: 0.625em;
					color: $white;
					text-shadow: 0 -1px 1px $gray-light2;
					content: '\f032';
					font-family: 'ReichmanSales';
					font-size: 2.0em;
					font-weight: normal;
					font-style: normal;
					line-height: 1;
					text-rendering: auto;
				}
			}
		}
	}
	.quote-message-customer-container {
		.quote-message-body-container {
			background-color: $white;
			border: 1px solid $gray-light2;
			border-radius: 0 0.375em 0.375em 0.375em;
			p {
				color: $gray-dark2;
			}
			&:before {
				left: 0.625em;
				color: $white;
				text-shadow: 0 -1px 1px $gray-light2;
			}
		}
	}
}
