.img-zoom-hover {
	position: relative;
	overflow: hidden;
	& > * {
		z-index: 2;
	}
	.img-zoom-hover-photo {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		transition: transform 2.0s ease-out;
	}
}

figure {
	margin: 0;
	padding: 0;
	figcaption {
		margin: 0;
		padding: 0.625rem 0 0 0;
		text-align: center;
		color: $gray-mid2;
		font-size: 0.9375rem;
	}
}