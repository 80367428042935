/* Main Dashboard Layout */
.agent-dashboard-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1.25em;
	align-items: start;
	padding: 0.625em 1.875em 6.25em 1.875em;
	@media (max-width: 991.9px) {
		grid-template-columns: 1fr;
		padding: 0.625em 0.9375rem 6.25rem 0.9375rem;
	}
}

/* All Cards */
.agent-dashboard-card {
	padding: 0.9375em;
	padding-bottom: 0;
	border-radius: 0.375em;
	border: 1px solid $gray-light2;
	background-color: $white;
	height: auto;
	.card-header {
		.card-title {
			display: inline-block;
			color: $brandcolor2;
		}
	}
	.agent-dashboard-table {
		display: grid;
		grid-gap: 0.5em 0.5em;
		justify-items: start;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			padding: 0;
			overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-header-divider {
			border-bottom: 1px solid $gray-mid2;
			margin: 0.125em 0 0.3125em 0;
		}
		.table-row-divider {
			border-bottom: 1px solid $gray-light2;
			margin: 0.3125em 0;
			&:first-child {
				// border-bottom: 1px solid $gray-mid2;
				// margin: 0;
			}
			&:last-child {
				border-bottom: none;
				margin: 0;
			}
		}
	}
}

/* Messages Card */
.agent-dashboard-card-messages {
	.agent-dashboard-messages-table {
		grid-template-columns: minmax(min-content, 0.25fr) 1fr minmax(min-content, 0.25fr) minmax(min-content, max-content);
		grid-gap: 0 0.3125rem;
		.table-header-divider {
			grid-column: span 4;
		}
		.table-row-divider {
			grid-column: span 4;
			margin: 0.625em 0;
		}
		.table-col-status {
			white-space: nowrap;
		}
		.table-col-date {
			white-space: nowrap;
		}
		.table-col-body {
			grid-column: 2 / span 3;
			font-size: 0.875em;
			color: $gray-mid2;
			p {
				margin: 0;
				padding: 0;
				line-height: 1.25;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
			}
		}
	}
}

/* Quotes Card */
.agent-dashboard-card-quotes {
	.agent-dashboard-quotes-table {
		grid-template-columns: minmax(min-content, 0.25fr) 1fr minmax(min-content, 0.25fr) minmax(min-content, max-content);
		.table-header-divider {
			grid-column: span 4;
			margin: 0.125em 0 0.625em 0;
		}
		.table-row-divider {
			grid-column: span 4;
			margin: 0.625em 0;
		}
		.table-col-status {
			white-space: nowrap;
		}
	}
}