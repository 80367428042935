.back-tab-top-left {
	position: relative;
	z-index: 1;
	margin-top: 5vh;
	&::before {
		background: inherit;
		overflow-x: hidden;
		content: '';
		display: inline-block;
		width: 20vw;
		height: 5vh;
		position: absolute;
		left: -2.5vh;
		z-index: -1;
		-webkit-backface-visibility: hidden; // for Chrome Windows
		top: -4vh;
		transform: skewX(45deg);
	}
}

.back-tab-top-right {
	position: relative;
	z-index: 1;
	margin-top: 5vh;
	&::before {
		background: inherit;
		overflow-x: hidden;
		content: '';
		display: inline-block;
		width: 20vw;
		height: 5vh;
		position: absolute;
		right: -2.5vh;
		z-index: -1;
		-webkit-backface-visibility: hidden; // for Chrome Windows
		top: -4vh;
		transform: skewX(-45deg);
	}
}

.back-tab-bottom-left {
	position: relative;
	z-index: 1;
	margin-bottom: 5vh;
	&::after {
		background: inherit;
		overflow-x: hidden;
		content: '';
		display: inline-block;
		width: 20vw;
		height: 5vh;
		left: -2.5vh;
		position: absolute;
		z-index: -1;
		-webkit-backface-visibility: hidden; // for Chrome Windows
		bottom: -4vh;
		transform: skewX(-45deg);
	}
}

.back-tab-bottom-right {
	position: relative;
	z-index: 1;
	margin-bottom: 5vh;
	&::after {
		background: inherit;
		overflow-x: hidden;
		content: '';
		display: inline-block;
		width: 20vw;
		height: 5vh;
		right: -2.5vh;
		position: absolute;
		z-index: -1;
		-webkit-backface-visibility: hidden; // for Chrome Windows
		bottom: -4vh;
		transform: skewX(45deg);
	}
}