/* Quote Detail Page */
.quote-detail-intro-row {
	padding-bottom: 1.25em;
	.quote-detail-intro-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		@media (max-width: 991.9px) {
			padding-bottom: 0.625rem;
		}
		p {
			font-size: 0.875em;
			margin-bottom: 0;
		}
	}
	.quote-detail-send-button-top-container {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		@media (max-width: 991.9px) {
			justify-content: center;
		}
	}
}

.quote-detail-list-container {
	margin: 0;
	padding: 0;
}

.form-group {
	margin-bottom: 0;
}

.product-type-list-products-edit-container {
	margin: 0;
	padding: 0;
	.product-type-list-products-edit {
		margin-bottom: 0;
		display: grid;
		grid-template-columns: minmax(min-content, auto) repeat(2, minmax(min-content, max-content)) minmax(min-content, auto) repeat(4, minmax(min-content, max-content));
		grid-gap: 0.75em 0.5em;
		justify-items: start;
		align-items: center;
		.table-col, .table-col-header {
			margin: 0;
			padding: 0;
			overflow: hidden;
			// white-space: nowrap;
			text-overflow: ellipsis;
		}
		.table-row-divider {
			grid-column: span 8;
			border-bottom: 1px solid $gray-light2;
			&:first-child {
				border-top: 1px solid $gray-mid2;
			}
			&:last-child {
				border-bottom: 1px solid $gray-mid2;
			}
		}
		input {
		padding: 0.125em 0.25em !important;
		height: auto;
		}
		select {
			padding: 0.125em 1.0em 0.125em 0.25em !important;
			height: auto;
		}
		.table-col-edit-button {
			//justify-self: end;
			padding-right: 0.125em;
		}
		.table-col-delete-button {
			a, button {
				color: $gray-mid2;
				&:hover {
					color: $brandcolor1-light;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
				}
			}
		}
		@media (max-width: 575.9px) {
			grid-template-columns: repeat(5, minmax(min-content, 1fr)) repeat(2, minmax(min-content, max-content)); 
			.table-col-header {
				display: none;
			}
			.table-col-product-title {
				grid-column: 1 / span 4;
			}
			.table-col-manufacturer {
				grid-column: 5 / span 4;
				justify-self: flex-end;
				// padding-right: 0.125em;
			}
			.table-col-msds-link, .table-col-label-link {
				display: none;
			}
			.table-col-qty-needed {
				grid-column: 1 / span 2;
				justify-self: stretch;
			}
			.table-col-pack-size {
				grid-column: 3 / span 4;
				justify-self: stretch;
				select {
					width: 100% !important;
				}
			}
		}
	}
}

.quote-detail-add-product-row {
	padding: 0 1em;
	.quote-detail-add-product-container {
		padding: 0.625em;
		background-color: $gray-white2;
		border-bottom: 1px solid $gray-mid2;
		.quote-detail-add-product-button {
			//background-color: $white;
			padding: 0.625em 2em 1em 2em;
			@media (max-width: 575.9px) {
				width: 100%;
			}
			.fa, .fac {
				font-size: 1.625em;
				transform: translateY(0.125em);
			}
		}
	}
}

.quote-detail-footer-row {
	margin-top: 1.25em;
	.quote-detail-view-more-button-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		.btn {
			margin: 0;
			white-space: nowrap;
			@media (max-width: 575.9px) {
				margin: 0.625rem 0;
				width: 100%;
			}
		}
	}
	.quote-detail-send-button-bottom-container {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		.btn {
			margin: 0;
			white-space: nowrap;
			@media (max-width: 575.9px) {
				margin: 0.625rem 0;
				width: 100%;
			}
		}
	}
}

