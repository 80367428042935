.user-login-dropdown {
	.dropdown-toggle {
		padding: 0.125em 0.3125em !important;
		// margin: 0 !important;
		font-size: 1.125em !important;
		display: flex;
		justify-content: center;
		align-items: center;
		.name-label {
			//font-size: 0.75rem;
			//font-weight: 700;
			color: $gray-mid2;
			//text-transform: uppercase;
			line-height: 1;
		}
	}
}

.user-profile-image {
	display: inline-block;
	position: relative;
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	overflow: hidden;
	margin: 0 0.625em 0 0;
	img {
		width: 100%;
		height: auto;
	}
	&::after {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10000;
		border-radius: 50%;
		-webkit-box-shadow: inset 0 -0.125rem 0.5rem 0 rgba($gray-dark3,0.2);
		-moz-box-shadow: inset 0 -0.125rem 0.5rem 0 rgba($gray-dark3,0.2);
		-ms-box-shadow: inset 0 -0.125rem 0.5rem 0 rgba($gray-dark3,0.2);
		-o-box-shadow: inset 0 -0.125rem 0.5rem 0 rgba($gray-dark3,0.2);
		box-shadow: inset 0 -0.125rem 0.5rem 0 rgba($gray-dark3,0.2);
	}
}

/* Notification Numbers on Elements */
.counter-number {
	padding: 0.125em 0.3125em;
	border-radius: 2.0em;
	background-color: $alertcolor;
	color: $white;
	font-family: $font-body1;
	font-size: 0.75rem;
}

.counter-number-sm {
	font-size: 0.625rem;
}

.counter-number-lg {
	font-size: 0.875rem;
}


.alert-new {
	margin-right: 0.3125em;
	color: $alertcolor;
	display: flex;
	justify-content: center;
	align-items: center;
	.fac-stack-2x {
		color: $alertcolor-light;
		opacity: 0;
		transform: scale(0.5);
		animation: alert-new-loop 1s infinite;
		-webkit-transition: all 1.0s linear;
		-moz-transition: all 1.0s linear;
		-ms-transition: all 1.0s linear;
		-o-transition: all 1.0s linear;
		transition: all 1.0s linear;
	}
}
@keyframes alert-new-loop {
	from {
		opacity: 0;
		transform: scale(0.5);
	}
	to {
		opacity: 0.75;
		transform: scale(1.0);
	}
}

